<template>
    <div>
    <div class="form-table" >
        <el-card class="card-pub-notice card-ext-center-title">
            <div style="text-align: center">
                <span style="font-size: 18px">
                    <input
                        class="factory_name"
                        v-model="dataForm.data.name"
                        :disabled="getDisable(NODE_CODE_VALUE.S1)"

                    />
                    {{ nameType }}
                </span>
            </div>
            <el-form
                :model="dataForm"
                :rules="dataRule"
                ref="dataForm"
                @keyup.enter.native="save('save')"
                label-width="120px"
                label-position="top"
                :disabled="isShowAll"
            >
                <span>编号：{{ dataForm.data.code}}</span>
                <!--基本信息-->
                <ex-card headerName="基本信息填写" class="card-margin">
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="工作负责人（监护人）">
                                <el-input
                                    v-model="dataForm.data.chargeName"
                                    placeholder="工作负责人"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="单位和班组">
                                <el-input
                                    v-model="dataForm.data.teamName"
                                    placeholder="请输入单位和班组"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="工作负责人及工作班总人数">
                                <el-input
                                    v-model="dataForm.data.personCount"
                                    placeholder="请输入单位和班组"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="计划开始时间">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    v-model="dataForm.data.planBeginTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="计划结束时间">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    v-model="dataForm.data.planEndTime"
                                    type="datetime"
                                    placeholder="选择日期"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="工作班人员（不包括工作负责人）">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.teamPerson"
                            placeholder="请输入工作班人员"
                            maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="工作任务" prop="operatingDuty">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.task"
                            placeholder="请输入工作任务"
                            maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="工作地点" prop="operatingDuty">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.workLocation"
                            placeholder="请输入工作地点"
                            maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                </ex-card>
                <!-- 安全措施 第三种-->
                <ex-card
                    headerName="工作要求的安全措施"
                    class="card-margin"
                    v-if="dataForm.data.type === 'elec_factory_work_ticket_3'"
                >
                    <el-form-item label="工作要求的安全措施">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                            type="textarea"
                            rows="3"
                            placeholder="工作要求的安全措施"
                            v-model="dataForm.data.safeOther"
                        ></el-input>
                    </el-form-item>
                </ex-card>
                <!-- 安全措施 非第三种-->
                <ex-card
                    headerName="工作要求的安全措施"
                    class="card-margin"
                    v-if="dataForm.data.type !== 'elec_factory_work_ticket_3'"
                >
                    <el-form-item label="工作条件" v-if="dataForm.data.type === 'elec_factory_work_ticket_2'">
                        <el-form-item label="相关高压设备状态">
                            <el-input
                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                type="textarea"
                                rows="3"
                                placeholder="相关高压设备状态"
                                v-model="dataForm.data.highEquipmentState"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="相关直流、低压及二次回路状态">
                            <el-input
                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                type="textarea"
                                rows="3"
                                placeholder="相关直流、低压及二次回路状态"
                                v-model="dataForm.data.loopState"
                            ></el-input>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item
                        label="应拉开的断路器（开关）和隔离开关（刀闸）（双重名称或编号）"
                        v-if="dataForm.data.type === 'elec_factory_work_ticket_1'"
                    >
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="断路器（开关）">
                                    <el-input
                                        type="textarea"
                                        rows="3"
                                        v-model="dataForm.data.safeBreaker"
                                        placeholder="断路器（开关）"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="隔离开关（刀闸）">
                                    <el-input
                                        type="textarea"
                                        rows="3"
                                        v-model="dataForm.data.safeDisconnector"
                                        placeholder="隔离开关（刀闸）"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="应投切的相关直流电源（空气开关、熔断器、连接片）、低压及二次回路">
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.safeCut"
                            placeholder="应投切的相关直流电源（空气开关、熔断器、连接片）、低压及二次回路"
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="应合上的接地刀闸 （双重名称或编号）、装设的接地线（装设地点）、应设绝缘挡板"
                        v-if="dataForm.data.type === 'elec_factory_work_ticket_1'"
                    >
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.safeClosed"
                            placeholder="应合上的接地刀闸 （双重名称或编号）、装设的接地线（装设地点）、应设绝缘挡板"
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应设遮栏、应挂标示牌（位置）" prop="district">
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.safeSignboard"
                            placeholder="应设遮栏、应挂标示牌（位置）"
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="其他安全措施和注意事项">
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.safeOther"
                            placeholder="其他安全措施和注意事项"
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="是否需线路对侧接地" v-if="dataForm.data.type === 'elec_factory_work_ticket_1'">
                        <el-radio-group v-model="dataForm.data.safeGround" :disabled="getDisable(NODE_CODE_VALUE.S1)">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="是否需办理二次设备及回路工作安全技术措施单">
                                <el-radio-group
                                    v-model="dataForm.data.safeReceipt"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                >
                                    <el-radio label="1">是</el-radio>
                                    <el-radio label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="二次设备及回路工作安全技术措施单" v-if="dataForm.data.safeReceipt == 1">
                        <span
                        >共<el-input v-model="dataForm.data.safeReceiptCount" style="width: 120px" :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input
                        >张</span
                        >
                        <!--<el-row :gutter="24" style="color: #ffffff">
                            <el-col :span="6">
                                <span>工作票编号： {{ dataForm.code }}</span>
                            </el-col>
                            <el-col :span="6" :offset="2">
                                <span>工作票负责人（审批人）：{{ dataForm.data.chargeName }}</span>
                            </el-col>
                        </el-row>-->
                    </el-form-item>
                </ex-card>
                <!--选择会签/签发-->
                <ex-card
                    headerName="会签/签发"
                    class="card-margin"
                    v-if="dataForm.data.type !== 'elec_factory_work_ticket_3'"
                >
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="请选择会签/签发">
                                <el-radio-group
                                    v-model="dataForm.data.isSign"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                    @change="radioChange"
                                    size="small"
                                >
                                    <el-radio label="0">会签</el-radio>
                                    <el-radio label="1">签发</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="isHqinput">
                            <el-form-item label="选择会签人">
                                <el-select v-model="dataForm.data.hqReceiver"
                                           filterable placeholder="请选择"
                                           @change="setReceiver('hqReceiver')"
                                           :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                >
                                    <el-option
                                        v-for="item in userList"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="isQfinput">
                            <el-form-item label="选择签发人">
                                <el-select v-model="dataForm.data.qfReceiver"
                                            filterable placeholder="请选择"
                                            @change="setReceiver('qfReceiver')"
                                            :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                >
                                    <el-option
                                        v-for="item in userList"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!-- 签发 -->
                <ex-card
                    headerName="签发"
                    class="card-margin"
                    v-if="getVisible(NODE_CODE_VALUE.S2)"
                >
                    <!--<el-row :gutter="24">
                        <el-col :span="24">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作票会签人签名
                                <el-button
                                        @click="openSignDlg(SIGN_TYPE.SIGN_COUNTERSIGN_SIGN)"
                                        style="display: inline-block"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S2)"
                                >
                                    点击进入电子签章
                                </el-button>
                            </div>
                            <img
                                    :src="signImg(dataForm.data.signCountersignSign)"
                                    style="width: 300px; height: auto; margin: 20px 0 20px"
                            />
                        </el-col>
                    </el-row>-->
                    <el-row :gutter="24">
                        <!--<el-col :span="6">
                            <el-form-item label="时间">
                                <el-input v-model="dataForm.data.signCountersignSignTime" disabled></el-input>
                            </el-form-item>
                        </el-col>-->
                        <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S2)">
                            <el-form-item label="选择签发人">
                                <el-select v-model="dataForm.data.qfReceiver"
                                           filterable placeholder="请选择"
                                           @change="setReceiver('qfReceiver')"
                                           :disabled="getDisable(NODE_CODE_VALUE.S2) ? true : false"
                                >
                                    <el-option
                                        v-for="item in userList"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!-- 厂站3选择接收人 -->
                <ex-card
                    headerName="接收人"
                    class="card-margin"
                    v-if="dataForm.nodeCode==='elec_factory_work_ticket_3_step_3'&& dataForm.data.type === 'elec_factory_work_ticket_3'"
                >
                    <el-row :gutter="24">
                        <el-col :span="6" >
                            <el-form-item label="选择接收人">
                                <el-select
                                    v-model="dataForm.data.jsReceiver"
                                    filterable placeholder="请选择"
                                    @change="setReceiver('jsReceiver')"
                                >
                                    <el-option
                                        v-for="item in userList1"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!-- 厂站3显示接收人 -->
                <ex-card
                    headerName="选择接收人"
                    class="card-margin"
                    v-if="dataForm.nodeCode==='elec_factory_work_ticket_3_step_4'&& dataForm.data.type === 'elec_factory_work_ticket_3'"
                >
                    <el-row :gutter="24">
                        <el-col :span="6" >
                            <el-form-item label="选择接收人">
                                <el-select
                                    v-model="dataForm.data.jsReceiver"
                                    filterable placeholder="请选择"
                                    @change="setReceiver('jsReceiver')"
                                    :disabled="getDisable(NODE_CODE_VALUE.S3) ? true : false"
                                >
                                    <el-option
                                        v-for="item in userList1"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!--接收人-->
                <ex-card
                    headerName="接收人"
                    class="card-margin"
                    v-if="getVisible(NODE_CODE_VALUE.S3) && dataForm.definitionCode !== 'elec_factory_work_ticket_3'"
                >
                    <!--<el-row :gutter="24">
                        <el-col :span="6">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作票签发人签名
                                <el-button
                                        @click="openSignDlg(SIGN_TYPE.SIGN_ISSUER_SIGN)"
                                        style="display: inline-block"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S3)"
                                >
                                    点击进入电子签章
                                </el-button>
                            </div>
                            <img
                                    :src="signImg(dataForm.data.signIssuerSign)"
                                    style="width: 300px; height: auto; margin: 20px 0 20px"
                            />
                        </el-col>
                    </el-row>-->
                    <el-row :gutter="24">
                        <!--<el-col :span="6">
                            <el-form-item label="时间">
                                <el-input v-model="dataForm.data.signIssuerSignTime" disabled></el-input>
                            </el-form-item>
                        </el-col>-->
                        <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S3)">
                            <el-form-item label="选择接收人">
                                <el-select v-model="dataForm.data.jsReceiver"
                                           filterable clearable placeholder="请选择"
                                           @change="setReceiver('jsReceiver')"
                                           :disabled="getDisable(NODE_CODE_VALUE.S3) ? true : false"
                                >
                                    <el-option
                                        v-for="item in userList1"
                                        :key="item.username"
                                        :value="item.username"
                                        :label="item.realName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!--接收-->
                <!--<ex-card headerName="接收" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S4)">
                    &lt;!&ndash;<el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="值班负责人签名：" prop="district">
                                <el-button
                                        @click="openSignDlg(SIGN_TYPE.GET_ON_DUTY_CHARGE_SIGN)"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                >点击进入电子签名
                                </el-button>
                                <br/>
                                <img :src="signImg(dataForm.data.getOnDutyChargeSign)" class="signature"/>
                            </el-form-item>
                        </el-col>
                    </el-row>&ndash;&gt;
                    <el-row :gutter="24">
                        &lt;!&ndash;<el-col :span="6">
                            <el-form-item label="时间">
                                <el-input v-model="dataForm.data.getOnDutyChargeSignTime" disabled></el-input>
                            </el-form-item>
                        </el-col>&ndash;&gt;
                        <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S4)">
                            <el-form-item label="选择接收人">
                            <el-select v-model="dataForm.data.jsReceiver"
                                       filterable clearable placeholder="请选择"
                                       :disabled="getDisable(NODE_CODE_VALUE.S4) ? true : false"
                            >
                                        <el-option
                                            v-for="item in userList1"
                                            :key="item.username"
                                            :value="item.username"
                                            :label="item.realName"
                                        ></el-option>
                                    </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>-->
                <!--工作许可-->
                <ex-card headerName="工作许可" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S4)">
                    <el-form-item label="安全措施是否满足工作要求：">
                        <el-radio-group
                            v-model="dataForm.data.safeMeetRequirement"
                            :disabled="getDisable(NODE_CODE_VALUE.S4)"
                        >
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="需补充或调整的安全措施：">
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.safeSupplement"
                            placeholder="需补充或调整的安全措施"
                            :disabled="getDisable(NODE_CODE_VALUE.S4)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="是否需以手触试：" v-if="dataForm.data.type === 'elec_factory_work_ticket_1'">
                        <el-radio-group v-model="dataForm.data.isTouch" :disabled="getDisable(NODE_CODE_VALUE.S4)">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        label="以手触试的具体部位："
                        v-if="dataForm.data.type === 'elec_factory_work_ticket_1'"
                    >
                        <el-input
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.touchPart"
                            placeholder="以手触试的具体部位"
                            :disabled="getDisable(NODE_CODE_VALUE.S4)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="线路对侧安全措施：" v-if="dataForm.data.type === 'elec_factory_work_ticket_1'">
                        <span>线路对侧安全措施：经值班调度员（配电网运维人员）（姓名） </span>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-input
                                    v-model="dataForm.data.onDutyDispatchName"
                                    placeholder="带电的母线、导线"
                                    :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <span>确认线路对侧已按要求执行</span>
                    </el-form-item>
                    <el-form-item label="工作地点保留的带电部位：">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="带电的母线、导线：">
                                    <el-input
                                        type="textarea"
                                        rows="3"
                                        v-model="dataForm.data.powerGeneratrix"
                                        placeholder="带电的母线、导线"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="带电的隔离开关（刀闸）：">
                                    <el-input
                                        type="textarea"
                                        rows="3"
                                        v-model="dataForm.data.powerDisconnector"
                                        placeholder="带电的隔离开关（刀闸）"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="其他：">
                                    <el-input
                                        type="textarea"
                                        rows="3"
                                        v-model="dataForm.data.powerOther"
                                        placeholder="其他"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="其他安全注意事项：">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S4)"
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.permitSafeOther"
                            placeholder="请输入其他安全注意事项"
                            maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                    <el-row :gutter="24" v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <el-col :span="4">
                            <el-form-item label="工作许可人签名：">
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.PERMIT_WORK_SIGN)"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >点击进入电子签名
                                </el-button> -->
                                <el-input v-model="dataForm.data.permitWorkSign" placeholder="请输入工作许可人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                <!-- <br/> -->
                                <!-- <img :src="signImg(dataForm.data.permitWorkSign)" class="signature"/> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <el-col :span="4">
                            <el-form-item label="工作负责人签名：">
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.PERMIT_WORK_CHARGE_SIGN)"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >点击进入电子签名
                                </el-button> -->
                                <el-input v-model="dataForm.data.permitWorkChargeSign" placeholder="请输入工作负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                <!-- <br/> -->
                                <!-- <img :src="signImg(dataForm.data.permitWorkChargeSign)" class="signature"/> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row  v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <el-col :span="6">
                            <el-form-item label="签名时间：">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    v-model="dataForm.data.permitSignTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!--安全交代-->
                <ex-card headerName="安全交代" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S5)">
                    <span>工作班人员确认工作负责人所交代布置的工作任务、安全措施和作业安全注意事项。</span>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="工作班人员签名：">
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.SAFE_WORK_TEAM_SIGN)"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >点击进入电子签名
                                </el-button> -->
                                <el-input v-model="dataForm.data.safeWorkTeamSign" placeholder="请输入工作班人员签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                <br/>
                                <!-- <img :src="signImg(dataForm.data.safeWorkTeamSign)" class="signature"/> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="签名时间：">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    v-model="dataForm.data.safeWorkTeamSignTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!--工作间断-->
                <ex-card headerName="工作间断" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S5)">
                    <ex-edit-table-form
                        size="mini"
                        :table-props="{
                            stripe: true,
                            border: true,
                            data: dataForm.data.breakList,
                            size: 'mini',
                            height: '260px',
                        }"
                        table-style="width: 100%"
                        class="task-book-info"
                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                    >
                        <template slot-scope="editScope">
                            <el-table-column label="间断信息">
                                <el-table-column align="center" label="工作间断时间">
                                    <template slot-scope="{row}">
                                        <el-form-item v-if="row === editScope.editingRow">
                                            <el-date-picker
                                                style="width: 220px"
                                                v-model="row.breakTime"
                                                type="datetime"
                                                placeholder="选择日期"
                                                format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                        <span v-else>{{ row.breakTime }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="间断工作许可人">
                                    <template slot-scope="scope">
                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                            <el-input v-model="scope.row.breakPermit"></el-input>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.breakPermit }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="间断工作负责人">
                                    <template slot-scope="scope">
                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                            <el-input v-model="scope.row.breakCharge"></el-input>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.breakCharge }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="工作开始时间">
                                    <template slot-scope="scope">
                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                            <el-date-picker
                                                style="width: 220px"
                                                v-model="scope.row.workTime"
                                                type="datetime"
                                                placeholder="选择日期"
                                                format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.workTime }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="开始工作许可人">
                                    <template slot-scope="scope">
                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                            <el-input v-model="scope.row.workPermit"></el-input>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.workPermit }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="开始工作负责人">
                                    <template slot-scope="scope">
                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                            <el-input v-model="scope.row.workCharge"></el-input>
                                        </el-form-item>
                                        <span v-else>{{ scope.row.workCharge }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="操作" type="action" width="120" fixed="right">
                                    <template slot-scope="scope">
                                        <el-button
                                            :data-delindex="scope"
                                            @click="deleteBreak(scope)"
                                            type="text"
                                            v-if="!getDisable(NODE_CODE_VALUE.S5)"
                                        >删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </template>
                    </ex-edit-table-form>
                    <div
                        class="ui-align--center ui-font--xxlarg"
                        style="font-size: 24px"
                        v-if="!getDisable(NODE_CODE_VALUE.S5)"
                    >
                        <i class="el-icon-circle-plus" title="添加间断信息" @click="addTableData(2)"></i>
                    </div>
                </ex-card>
                <!-- 工作变更 -->
                <ex-card headerName="工作变更" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S5)">
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="工作任务"></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="不需变更安全措施下增加的工作内容">
                                <el-input
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    type="textarea"
                                    rows="3"
                                    v-model="dataForm.data.changeList[0].taskNewTask"
                                    placeholder="请输入工作班人员"
                                    maxlength="500"
                                    show-word-limit
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作负责人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_TASK_CHARGE_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].taskChargeSign" placeholder="请输入工作负责人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].taskChargeSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作许可人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_TASK_PERMIT_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].taskPermitSign" placeholder="请输入工作许可人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].taskPermitSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="签名时间">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    v-model="dataForm.data.changeList[0].taskSignTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="工作负责人"></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作票签发人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_ISSUER_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].chargeIssuerSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].chargeIssuerSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>原工作负责人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_PRE_CHARGE_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].chargePreChargeSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].chargePreChargeSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>现工作负责人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_CURRENT_CHARGE_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].chargeCurrentChargeSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].chargeCurrentChargeSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作许可人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_PERMIT_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].chargePermitSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.changeList[0].chargePermitSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="签名时间">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    v-model="dataForm.data.changeList[0].chargeSignTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="工作班人员"></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <ex-edit-table-form
                            size="mini"
                            :table-props="{
                                stripe: true,
                                border: true,
                                data: dataForm.data.changeList[0].itemList,
                                size: 'mini',
                                height: '260px',
                            }"
                            table-style="width: 100%"
                            class="task-book-info"
                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                        >
                            <template slot-scope="editScope">
                                <el-table-column label="变更信息">
                                    <el-table-column align="center" label="变更情况">
                                        <template slot-scope="scope">
                                            <el-form-item v-if="scope.row === editScope.editingRow">
                                                <el-input v-model="scope.row.content"></el-input>
                                            </el-form-item>
                                            <span v-else>{{ scope.row.content }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="工作许可人/签发人">
                                        <template slot-scope="scope">
                                            <el-form-item v-if="scope.row === editScope.editingRow">
                                                <el-input v-model="scope.row.sign"></el-input>
                                            </el-form-item>
                                            <span v-else>{{ scope.row.sign }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="工作负责人">
                                        <template slot-scope="scope">
                                            <el-form-item v-if="scope.row === editScope.editingRow">
                                                <el-input v-model="scope.row.charge"></el-input>
                                            </el-form-item>
                                            <span v-else>{{ scope.row.charge }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="变更时间">
                                        <template slot-scope="scope">
                                            <el-form-item v-if="scope.row === editScope.editingRow">
                                                <el-date-picker style="width: 220px"
                                                                v-model="scope.row.changeTime"
                                                                type="datetime"
                                                                placeholder="选择日期"
                                                                format="yyyy-MM-dd HH:mm"
                                                                value-format="yyyy-MM-dd HH:mm"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                            <span v-else>{{ scope.row.changeTime }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        label="操作"
                                        type="action"
                                        width="120"
                                        fixed="right"
                                    >
                                        <template slot-scope="scope">
                                            <el-button
                                                :data-delindex="scope"
                                                @click="deleteChangeItem(scope)"
                                                type="text"
                                                v-if="!getDisable(NODE_CODE_VALUE.S5)"
                                            >删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table-column>
                            </template>
                        </ex-edit-table-form>
                        <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px"
                             v-if="!getDisable(NODE_CODE_VALUE.S5)">
                            <i class="el-icon-circle-plus" title="添加变更信息" @click="addTableData(3)"></i>
                        </div>
                    </el-row>
                </ex-card>

                <!-- 工作延期 -->
                <ex-card headerName="工作延期" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S5)">
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="有效期延长至">
                                <el-date-picker style="width: 280px"
                                                v-model="dataForm.data.delayList[0].delayDelayTerm"
                                                :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                type="datetime"
                                                placeholder="选择日期"
                                                format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="申请方式">
                                <el-input type="textarea"
                                          rows="3"
                                          v-model="dataForm.data.delayList[0].delayApplyType"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作许可人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.DELAY_PERMIT_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].delayPermitSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.delayList[0].delayPermitSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div>
                                <span style="color: #f56c6c; margin-right: 5px">*</span>工作负责人签名
                                <!-- <el-button
                                    @click="openSignDlg(SIGN_TYPE.DELAY_CHARGE_SIGN)"
                                    style="display: inline-block"
                                    type="primary"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    点击进入电子签章
                                </el-button> -->
                                <el-input v-model="dataForm.data.changeList[0].delayChargeSign" placeholder="请输入工作票签发人签名"></el-input>
                            </div>
                            <br>
                            <!-- <img
                                :src="signImg(dataForm.data.delayList[0].delayChargeSign)"
                                style="width: 300px; height: auto; margin: 20px 0 20px"
                            /> -->
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="签名时间">
                                <el-date-picker
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    v-model="dataForm.data.delayList[0].delaySignTime"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </ex-card>
                <!--工作票的终结-->
                <ex-card headerName="工作票的终结" class="card-margin" v-if="getVisible(NODE_CODE_VALUE.S5)">
                    <el-form-item label="作业终结：">
                        <span
                        >全部作业已结束，检修临时安全措施已拆除，已恢复作业开始前状态，作业人员已全部撤离，材料工具已清理完毕。</span
                        >
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="工作负责人签名：">
                                    <!-- <el-button
                                        @click="openSignDlg(SIGN_TYPE.FINISH_WORK_CHARGE_SIGN)"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    >点击进入电子签名
                                    </el-button
                                    > -->
                                    <el-input v-model="dataForm.data.workOperatingSignature" placeholder="请输入工作负责人签名"   :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                    <br/>
                                    <!-- <img :src="signImg(dataForm.data.finishWorkChargeSign)"
                                         class="signature"
                                    /> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="工作许可人签名：">
                                    <!-- <el-button
                                        @click="openSignDlg(SIGN_TYPE.FINISH_WORK_PERMIT_SIGN)"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    >点击进入电子签名
                                    </el-button
                                    > -->
                                    <el-input v-model="dataForm.data.finishWorkPermitSign" placeholder="请输入工作许可人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                    <br/>
                                    <!-- <img :src="signImg(dataForm.data.finishWorkPermitSign)"
                                         class="signature"
                                    /> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="签名时间">
                                    <el-date-picker
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                        v-model="dataForm.data.finishWorkSignTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item
                        label="许可人措施终结"
                        v-if="dataForm.data.type === 'elec_factory_work_ticket_1'"
                    >
                        <span>临时遮栏已拆除，标示牌已取下，常设遮栏已恢复等。</span>
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="工作许可人签名：">
                                    <!-- <el-button
                                        @click="openSignDlg(SIGN_TYPE.FINISH_PERMIT_SIGN)"
                                        type="primary"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    >点击进入电子签名
                                    </el-button
                                    > -->
                                    <el-input v-model="dataForm.data.finishPermitSign" placeholder="请输入工作许可人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                    <br/>
                                    <!-- <img :src="signImg(dataForm.data.finishPermitSign)"
                                         class="signature"
                                    /> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="时间">
                                    <el-date-picker
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                        v-model="dataForm.data.finishPermitSignTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                            type="textarea"
                            rows="3"
                            v-model="dataForm.data.remark"
                            placeholder="请输入工作班人员备注（工作转移、安全交代补充签名等）："
                            maxlength="500"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                </ex-card>
            </el-form>
        </el-card>
        <!-- <sign ref="sign" v-show="showSign" @success="signCallback"></sign> -->
        <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
            <el-table
                :data="dataForm.rejectNodes"
                border
                highlight-current-row
                @current-change="handleCurrentChange"
                style="width: 100%; height: 200px; overflow-y: auto !important"
            >
                <el-table-column align="center" fixed prop="name" :show-overflow-tooltip="true" label="步骤名称">
                </el-table-column>
            </el-table>
            <div style="text-align:center;margin-top:10px;">
                <el-button type="primary" @click="rejectSubmit">驳回</el-button>
            </div>
        </el-dialog>
    </div>
    <div class="submit-btn-group button-fixed" v-if="dataForm.curNodeCode === NODE_CODE_VALUE.S3">
        <template v-if="!dataForm.isInfo">
            <el-button @click="save('saveAndNext')" type="primary">{{submitButtonText}}</el-button>
            <el-button @click="dialogVisible = true" type="danger">驳回</el-button>
        </template>
        <el-button @click="closeForm">关闭</el-button>
        <el-button @click="dataExport" type="danger">导出</el-button>
    </div>
    <div class="submit-btn-group button-fixed" v-else>
        <template v-if="!dataForm.isInfo">
            <el-button @click="save('save')" type="primary">{{saveButtonText}}</el-button>
            <el-button @click="save('saveAndNext')" type="primary">{{submitButtonText}}</el-button>
            <el-button @click="dialogVisible = true" type="danger">驳回</el-button>
        </template>
        <el-button @click="closeForm">关闭</el-button>
        <el-button @click="dataExport" type="danger">导出</el-button>
    </div>
    </div>
</template>

<script>
import Sign from '@/components/Sign/index';
import {fileDownload} from '@/utils';
import dayjs from 'dayjs';

export default {
    name: 'WorkTicketFactory',
    components: {
        Sign
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            showSign: false,
            nodeCodeMap: new Map(), //正在处理的环节编码权值，用于判断显示/隐藏及编辑
            flow: {},
            nameType: '',
            dataForm: {
                instanceId: null, //实例id
                definitionCode: '', //流程编码
                nodeCode: '', //下一环节code
                operationType: '', //操作类型 save saveAndNext reject
                jumpNodeCode: '', //跳转环节
                user: '',
                roles: [],
                nextUsers: null, //下一环节处理人
                nextRoles: null, //下一角色
                curNodeCode: '', //当前环节编码
                isInfo: '', //是否是详情
                isAddName: false, //是否自动补全过名称
                extraProp: { //查询条件
                    code: ''
                },
                data: {
                    id: null, //
                    name: '', //厂站名称
                    code: '', //编码
                    type: '', //类型（1-第一种 2-第二种 3-第三种）
                    chargeName: '', //工作负责人
                    teamName: '', //单位、班组名称
                    personCount: '', //工作负责人及工作班总人数
                    planBeginTime: '', //计划工时开始
                    planEndTime: '', //计划工时结束
                    teamPerson: '', //工作班人员（不包括工作负责人）
                    task: '', //工作任务
                    highEquipmentState: '', //相关高压设备状态
                    loopState: '', //相关直流，低压二次回路状态
                    workLocation: '', //工作地段
                    safeBreaker: '', //断路器（开关）
                    safeDisconnector: '', //隔离开关（刀闸
                    safeCut: '', //安全措施——应投切的相关直流电源（空气开关，熔断器，连接片），低压及二次回路
                    safeClosed: '', //安全措施——应合上的接地刀闸（双重名称或编号），装设的接地线（装设地点），应设绝缘挡板
                    safeSignboard: '', //安全措施——遮拦、标示牌
                    safeOther: '', //其他安全措施和注意事项
                    safeGround: '1', //安全措施——是否需要线路对侧接地（0.是 1.否）
                    safeReceiptCount: 0,
                    safeReceipt: '1', //是否需办理二次设备及回路工作安全技术措施单（0 是 1 否）
                    signIssuerSign: '', //签发——工作票签发人签名
                    signIssuerSignTime: '', //签发——工作票签发人签名时间
                    signCountersignSign: '', //签发——工作票会签人签名
                    signCountersignSignTime: '', //签发——工作票会签人签名时间
                    getOnDutyChargeSign: '', //接收——值班负责人签名
                    getOnDutyChargeSignTime: '', //接收——值班负责人签名时间
                    safeMeetRequirement: '1', //工作许可——是否落实工作要求
                    safeSupplement: '', //工作许可——需补充或调整的安全措施
                    isTouch: '1', //工作许可——是否需要以手触试
                    touchPart: '', //工作许可——以手触试的具体部位
                    onDutyDispatchName: '', //值班调度员姓名
                    powerGeneratrix: '', //工作许可—工作地点保留的带电部位—带电的母线，导线
                    powerDisconnector: '', //工作许可—工作地点保留的带电部位—隔离开关（刀闸）
                    powerOther: '', //工作许可—工作地点保留的带电部位—其他
                    permitSafeOther: '', //工作许可—其他安全注意事项
                    permitWorkSign: '', //工作许可——许可人签名
                    permitWorkChargeSign: '', //工作许可——工作负责人签名
                    permitSignTime: '', //工作许可——签名时间
                    safeWorkTeamSign: '', //安全交代——工作班人员签名
                    safeWorkTeamSignTime: '', //安全交代——工作班人员签名时间
                    finishWorkChargeSign: '', //工作票的终结——作业终结——工作负责人签名
                    finishWorkPermitSign: '', //工作票的终结——作业终结——工作许可人签名
                    finishWorkSignTime: '', //工作票的终结——作业终结——签名时间
                    finishPermitSign: '', //工作票的终结——许可人措施终结——许可人签名
                    finishPermitSignTime: '', //工作票的终结——许可人措施终结——签名时间
                    finishTime: '', //工作票的终结——终结时间
                    remark: '', //备注
                    breakList: [], //工作间断
                    workflowCreateBy: '', //发起人
                    workflowCreateTime: '', //发起时间
                    isFirstSubmit: true, //是否是第一次提交，用于处理第一人保存，第二人提交的情况
                    changeList: [{ //变更
                        id: '',
                        workTicketType: '', //工作票类型
                        workTicketId: '', //工作票id
                        taskNewTask: '', //工作任务——变更内容
                        taskChargeSign: '', //工作任务——工作负责人签名
                        taskPermitSign: '', //工作任务——工作许可人人签名
                        taskSignTime: '', //工作任务——签名时间
                        chargeIssuerSign: '', //工作负责人——工作票签发人签名
                        chargePreChargeSign: '', //工作负责人——原工作负责人签名
                        chargeCurrentChargeSign: '', //工作负责人——现工作负责人签名
                        chargePermitSign: '', //工作负责人——工作许可人签名
                        chargeSignTime: '', //工作负责人——签名时间
                        itemList: [], //变更项
                    }],
                    delayList: [{ //延期
                        id: '',
                        workTicketType: '', //工作票类型
                        workTicketId: '', //工作票id
                        delayDelayTerm: '', //延期后有效期
                        delayPermitSign: '', //工作许可人签名
                        delayChargeSign: '', //工作负责人签名
                        delayApplyType: '', //申请方式
                        delaySignTime: '', //签名时间
                    }],
                    saveNotSubmit: null, //保存未提交

                    isSign: null, //是否会签 0-会签 1-签发
                    hqQfReceiver: '', //会签/签发
                    hqReceiver: '', //会签人
                    qfReceiver: '', //签发人
                    jsReceiver: '', //接收人
                }
            },
            //环节编码权值
            NODE_CODE_VALUE: {},
            //签名
            SIGN_TYPE: {
                SIGN_ISSUER_SIGN: 'signIssuerSign', //签发——工作票签发人签名
                SIGN_COUNTERSIGN_SIGN: 'signCountersignSign', //签发——工作票会签人签名
                GET_ON_DUTY_CHARGE_SIGN: 'getOnDutyChargeSign', //接收——值班负责人签名
                PERMIT_WORK_SIGN: 'permitWorkSign', //工作许可——许可人签名
                PERMIT_WORK_CHARGE_SIGN: 'permitWorkChargeSign', //工作许可——工作负责人签名
                SAFE_WORK_TEAM_SIGN: 'safeWorkTeamSign', //安全交代——工作班人员签名
                FINISH_WORK_CHARGE_SIGN: 'finishWorkChargeSign', ////工作票的终结——作业终结——工作负责人签名
                FINISH_WORK_PERMIT_SIGN: 'finishWorkPermitSign', //工作票的终结——作业终结——工作许可人签名
                FINISH_PERMIT_SIGN: 'finishPermitSign', //工作票的终结——许可人措施终结——许可人签名
                CHANGE_TASK_CHARGE_SIGN: 'taskChargeSign', //变更——工作任务——工作负责人签名
                CHANGE_TASK_PERMIT_SIGN: 'taskPermitSign', //变更——工作任务——工作许可人人签名
                CHANGE_CHARGE_ISSUER_SIGN: 'chargeIssuerSign', //变更——工作负责人——工作票签发人签名
                CHANGE_CHARGE_PRE_CHARGE_SIGN: 'chargePreChargeSign', //变更——工作负责人——原工作负责人签名
                CHANGE_CHARGE_CURRENT_CHARGE_SIGN: 'chargeCurrentChargeSign', //变更——工作负责人——现工作负责人签名
                CHANGE_CHARGE_PERMIT_SIGN: 'chargePermitSign', //工作负责人——工作许可人签名
                DELAY_PERMIT_SIGN: 'delayPermitSign', //延期——工作许可人签名
                DELAY_CHARGE_SIGN: 'delayChargeSign' //工作负责人签名
            },
            sign: {
                signIssuerSign: {
                    //签发——工作票签发人签名
                    file: null,
                    signatureAdvice: ''
                },
                signCountersignSign: {
                    //签发——工作票会签人签名
                    file: null,
                    signatureAdvice: ''
                },
                getOnDutyChargeSign: {
                    //接收——值班负责人签名
                    file: null,
                    signatureAdvice: '',
                },
                permitWorkSign: {
                    //工作许可——许可人签名
                    file: null,
                    signatureAdvice: '',
                },
                permitWorkChargeSign: {
                    //工作许可——工作负责人签名
                    file: null,
                    signatureAdvice: '',
                },
                safeWorkTeamSign: {
                    //安全交代——工作班人员签名
                    file: null,
                    signatureAdvice: '',
                },
                finishWorkChargeSign: {
                    //工作票的终结——作业终结——工作负责人签名
                    file: null,
                    signatureAdvice: '',
                },
                finishWorkPermitSign: {
                    //工作票的终结——作业终结——工作许可人签名
                    file: null,
                    signatureAdvice: '',
                },
                finishPermitSign: {
                    //工作票的终结——许可人措施终结——许可人签名
                    file: null,
                    signatureAdvice: '',
                },
                taskChargeSign: {
                    //变更——工作任务——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                taskPermitSign: {
                    //变更——工作任务——工作许可人人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargeIssuerSign: {
                    //变更——工作负责人——工作票签发人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargePreChargeSign: {
                    //变更——工作负责人——原工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargeCurrentChargeSign: {
                    //变更——工作负责人——现工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargePermitSign: {
                    //变更——工作负责人——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                delayPermitSign: {
                    //延期——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                delayChargeSign: {
                    //延期——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                }
            },
            dataRule: {
                district: [{required: false, message: '不能为空', trigger: 'blur'}]
            },
            dialogVisible: false,

            currentRow: null,
            userList: [], //发几部人员
            userList1: [], //昆自运维
            onDutyUserList: [], //值班负责人 厂站三新建默认选择
            isShowAll:false,
            isQfinput:false,
            isHqinput:true,
            saveButtonText:"保存",
            submitButtonText:"保存并发布",
        };
    },
    methods: {
        //删除变更
        deleteChangeItem({$index, row}) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning'
            }).then(() => {
                this.dataForm.data.changeList[0].itemList.splice($index, 1);
            });
        },
        //删除间断
        deleteBreak({$index, row}) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning'
            }).then(() => {
                this.dataForm.data.breakList.splice($index, 1);
            });
        },
        init(item) {
            if (item.name) {
                this.nameType = item.name;
            }
            if (item.definitionCode) {
                if (item.definitionCode === 'elec_factory_work_ticket_1') {
                    this.nameType = '厂站第一种工作票';
                }
                if (item.definitionCode === 'elec_factory_work_ticket_2') {
                    this.nameType = '厂站第二种工作票';
                }
                if (item.definitionCode === 'elec_factory_work_ticket_3') {
                    this.nameType = '厂站第三种工作票';
                }
            }
            if (item.instanceId) {
                //编辑
                this.dataForm = item;
            } else {
                //新建
                //获取用户信息
                this.dataForm.user = this.$store.state.userInfo.username;
                this.dataForm.roles = this.$store.state.userRole;
                // this.dataForm.data.chargeName = this.$store.state.userInfo.realName;
                /*this.$client.getUserInfo().then((res) => {
                        if (res.succeed) {
                            let user = res.user;
                            if (user && user.orgList) {
                                user.orgList.forEach(item => {
                                    this.dataForm.data.teamName += ',' + item.name;
                                });
                                if (this.dataForm.data.teamName && this.dataForm.data.teamName.length) {
                                    this.dataForm.data.teamName = this.dataForm.data.teamName.slice(1);
                                }
                            }
                        }
                    });*/
                //新建——初始化
                this.dataForm.data.type = item.code;
                this.dataForm.data.workflowCreateTime = this.$dayjs(new Date()).format('YYYY-MM-DD HH:mm');
                //获取流程参数
                this.dataForm.definitionCode = item.code || item.definitionCode;
                this.dataForm.operationType = 'save';
                //获取instanceId和nodeCode
                this.$client.workflowInstanceProcess(this.dataForm).then((res) => {
                    if (res.code === 0) {
                        this.dataForm.instanceId = res.data.instance.id;
                        this.dataForm.nodeCode = res.data.nextNode.code;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                });
                //发起人
                this.dataForm.data.workflowCreateBy = this.$store.state.userInfo.realName;
                //发起时间
            }
            this.initNodeCodeValue();
            this.initNodeCodeMap();
            this.initOnDutyUserList();
            this.radioChange(item.data.isSign)
            this.isShowAll = item.isShowAll;
            if(this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3){
                this.submitButtonText = "发送"
            }else if(this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4){
                this.submitButtonText = "生成票号并发布"
            }
        },
        initOnDutyUserList() {
            let roleCodeList = [];
            roleCodeList.push('LPZBFZR');
            this.$client.queryUserByRoleCodeList(roleCodeList).then((res) => {
                if (res.data) {
                    for (let i = 0; i < res.data.length; i++) {
                        this.onDutyUserList.push(res.data[i].username);
                    }
                }
            });
        },
        async save(type) {
            let msg = this.dataForm.nodeCode === this.NODE_CODE_VALUE.S3 ? '接收人接收' : '确定保存';
            let confirm = await this.$confirm(msg, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (confirm !== 'confirm') {
                return;
            }
            let workTicketType = this.isGenCode();
            //设置发起人
            if (type === 'saveAndNext' && this.dataForm.data.isFirstSubmit) {
                this.dataForm.data.isFirstSubmit = false;
                this.dataForm.data.workflowCreateBy = this.$store.state.userInfo.realName;
            }
            if (workTicketType && !this.dataForm.code) {
                //待接收时需要生成code
                this.$client.getNextWorkTicketCode(workTicketType).then((res) => {
                    let code = res.data;
                    this.dataForm.data.code = code;
                    if (!this.dataForm.extraProp) {
                        this.dataForm.extraProp = {};
                    }
                    this.dataForm.extraProp.code = code;
                    this.doSave(type);
                });
            } else {
                this.doSave(type);
            }
        },
        //是否生成code，只有签发后才能生成code
        isGenCode() {
            let workTicketType = '';
            switch (this.dataForm.nodeCode) {
                case 'elec_line_work_ticket_1_step_4':
                    workTicketType = 'X1';
                    break;
                case 'elec_line_work_ticket_2_step_4':
                    workTicketType = 'X2';
                    break;
                case 'elec_factory_work_ticket_1_step_4':
                    workTicketType = 'C1';
                    break;
                case 'elec_factory_work_ticket_2_step_4':
                    workTicketType = 'C2';
                    break;
                case 'elec_factory_work_ticket_3_step_4':
                    workTicketType = 'C3';
                    break;
                default:
            }
            return workTicketType;
        },
        doSave(type) {
            if (this.dataForm.data.planBeginTime && this.dataForm.data.planEndTime && this.dataForm.data.planBeginTime > this.dataForm.data.planEndTime) {
                this.$message.error('计划工作时间（开始）不能大于计划工作时间（结束）');
                return;
            }
            //处理方式
            this.dataForm.operationType = type;
            //是否是保存未提交
            this.dataForm.data.saveNotSubmit = type === 'save';
            //发起人
            this.dataForm.data.startUser = this.dataForm.user;

            if(!this.dataForm.data.saveNotSubmit){
                // 指定下一步只能是发起人处理
                if (this.dataForm.nodeCode === 'elec_factory_work_ticket_3_step_5' || this.dataForm.nodeCode === 'elec_factory_work_ticket_2_step_5' || this.dataForm.nodeCode === 'elec_factory_work_ticket_1_step_5') {
                    this.dataForm.nextUsers = [this.dataForm.data.startUser];
                }

                // 如果存在接收人，则指定下一步处理人
                if ((this.dataForm.curNodeCode === '' || this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S1) && this.dataForm.data.hqReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.hqReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S2 && this.dataForm.data.qfReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.qfReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3 && this.dataForm.data.jsReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.jsReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4) { //执行中
                    //接收后发给变电站人员进入执行中
                    this.dataForm.nextRoles = [15];
                }

                if (this.dataForm.definitionCode === 'elec_factory_work_ticket_3') {
                    this.dataForm.nextUsers = this.onDutyUserList;
                }
            }
            

            //签名校验
            if (this.dataForm.nodeCode === 'elec_factory_work_ticket_3_step_3' && this.dataForm.data.type === 'elec_factory_work_ticket_3') {
                if (!this.dataForm.data.jsReceiver) {
                    this.$message.error('请选择接收人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S1)) {
                if ((this.dataForm.data.isSign !== '0' && this.dataForm.data.isSign !== '1') ||(!this.dataForm.data.hqReceiver && !this.dataForm.data.qfReceiver)) {
                    this.$message.error('请选择会签/签发人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S2)) {
                if (!this.dataForm.data.qfReceiver) {
                    this.$message.error('请选择签发人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S3)) {
                if (!this.dataForm.data.jsReceiver) {
                    this.$message.error('请选择接收人');
                    return;
                }
            }
            if (type === 'save') {
                this.dataForm.nextUsers = [this.$store.state.userInfo.username];
            }
            this.$client.workflowInstanceProcess(this.dataForm).then(data => {
                if (data.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.closeForm(1);
                }
            });
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        //添加接地线/间断/变更
        addTableData(type) {
            if (type === 2) {
                //间断
                if (!this.dataForm.data.breakList) {
                    this.dataForm.data.breakList = [];
                }
                let s = {
                    workTicketType: this.dataForm.data.type,
                    breakTime: '', //工作间断时间
                    breakPermit: '', //间断工作许可人
                    breakCharge: '', //间断工作负责人
                    workTime: '', //工作开始时间
                    workPermit: '', //开始工作许可人
                    workCharge: '', //开始工作负责人
                };
                this.dataForm.data.breakList.push(s);
            } else if (type === 3) {
                //变更
                if (!this.dataForm.data.changeList[0].itemList) {
                    this.dataForm.data.changeList[0].itemList = [];
                }
                let s = {
                    content: '', //变更内筒
                    sign: '', //许可人/签发人
                    charge: '', //工作负责人
                    changeTime: '', //变更时间
                };
                this.dataForm.data.changeList[0].itemList.push(s);
            }
        },
        deleteRow(index, list) {
            list.splice(index, 1);
        },
        openSignDlg(type) {
            this.showSign = true;
            this.$nextTick(() => {
                this.$refs.sign.init(type);
            });
        },
        signCallback(data, type) {
            switch (type) {
                case this.SIGN_TYPE.SIGN_ISSUER_SIGN:
                    //签发——工作票签发人签名
                    this.$set(this.sign.signIssuerSign, 'file', data);
                    this.sign.signIssuerSign.signatureName = '签发——工作票签发人签名';
                    this.dataForm.data.signIssuerSign = this.sign.signIssuerSign.file.id;
                    break;
                case this.SIGN_TYPE.SIGN_COUNTERSIGN_SIGN:
                    //签发——工作票会签人签名
                    this.$set(this.sign.signCountersignSign, 'file', data);
                    this.sign.signCountersignSign.signatureName = '签发——工作票会签人签名';
                    this.dataForm.data.signCountersignSign = this.sign.signCountersignSign.file.id;
                    break;
                case this.SIGN_TYPE.GET_ON_DUTY_CHARGE_SIGN:
                    //接收——值班负责人签名
                    this.$set(this.sign.getOnDutyChargeSign, 'file', data);
                    this.sign.getOnDutyChargeSign.signatureName = '接收——值班负责人签名';
                    this.dataForm.data.getOnDutyChargeSign = this.sign.getOnDutyChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.PERMIT_WORK_SIGN:
                    //工作许可——许可人签名
                    this.$set(this.sign.permitWorkSign, 'file', data);
                    this.sign.permitWorkSign.signatureName = '工作许可——许可人签名';
                    this.dataForm.data.permitWorkSign = this.sign.permitWorkSign.file.id;
                    break;
                case this.SIGN_TYPE.PERMIT_WORK_CHARGE_SIGN:
                    //工作许可——工作负责人签名
                    this.$set(this.sign.permitWorkChargeSign, 'file', data);
                    this.sign.permitWorkChargeSign.signatureName = '工作许可——工作负责人签名';
                    this.dataForm.data.permitWorkChargeSign = this.sign.permitWorkChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.SAFE_WORK_TEAM_SIGN:
                    //安全交代——工作班人员签名
                    this.$set(this.sign.safeWorkTeamSign, 'file', data);
                    this.sign.safeWorkTeamSign.signatureName = '安全交代——工作班人员签名';
                    this.dataForm.data.safeWorkTeamSign = this.sign.safeWorkTeamSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_WORK_CHARGE_SIGN:
                    //工作票的终结——作业终结——工作负责人签名
                    this.$set(this.sign.finishWorkChargeSign, 'file', data);
                    this.sign.finishWorkChargeSign.signatureName = '工作票的终结——作业终结——工作负责人签名';
                    this.dataForm.data.finishWorkChargeSign = this.sign.finishWorkChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_WORK_PERMIT_SIGN:
                    //工作票的终结——作业终结——工作许可人签名
                    this.$set(this.sign.finishWorkPermitSign, 'file', data);
                    this.sign.finishWorkPermitSign.signatureName = '工作票的终结——作业终结——工作许可人签名';
                    this.dataForm.data.finishWorkPermitSign = this.sign.finishWorkPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_PERMIT_SIGN:
                    //工作票的终结——许可人措施终结——许可人签名
                    this.$set(this.sign.finishPermitSign, 'file', data);
                    this.sign.finishPermitSign.signatureName = '工作票的终结——许可人措施终结——许可人签名';
                    this.dataForm.data.finishPermitSign = this.sign.finishPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_TASK_CHARGE_SIGN:
                    //变更——工作任务——工作负责人签名
                    this.$set(this.sign.taskChargeSign, 'file', data);
                    this.sign.taskChargeSign.signatureName = '变更——工作任务——工作负责人签名';
                    this.dataForm.data.changeList[0].taskChargeSign = this.sign.taskChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_TASK_PERMIT_SIGN:
                    //变更——工作任务——工作负责人签名
                    this.$set(this.sign.taskPermitSign, 'file', data);
                    this.sign.taskPermitSign.signatureName = '变更——工作任务——工作负责人签名';
                    this.dataForm.data.changeList[0].taskPermitSign = this.sign.taskPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_ISSUER_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargeIssuerSign, 'file', data);
                    this.sign.chargeIssuerSign.signatureName = '变更——工作负责人——工作负责人签名';
                    this.dataForm.data.changeList[0].chargeIssuerSign = this.sign.chargeIssuerSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_PRE_CHARGE_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargePreChargeSign, 'file', data);
                    this.sign.chargePreChargeSign.signatureName = '变更——工作负责人——工作负责人签名';
                    this.dataForm.data.changeList[0].chargePreChargeSign = this.sign.chargePreChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_CURRENT_CHARGE_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargeCurrentChargeSign, 'file', data);
                    this.sign.chargeCurrentChargeSign.signatureName = '变更——工作负责人——现工作负责人签名';
                    this.dataForm.data.changeList[0].chargeCurrentChargeSign = this.sign.chargeCurrentChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_PERMIT_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargePermitSign, 'file', data);
                    this.sign.chargePermitSign.signatureName = '变更——工作负责人——工作许可人签名';
                    this.dataForm.data.changeList[0].chargePermitSign = this.sign.chargePermitSign.file.id;
                    break;
                case this.SIGN_TYPE.DELAY_PERMIT_SIGN:
                    //延期——工作许可人签名
                    this.$set(this.sign.delayPermitSign, 'file', data);
                    this.sign.delayPermitSign.signatureName = '延期——工作许可人签名';
                    this.dataForm.data.delayList[0].delayPermitSign = this.sign.delayPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.DELAY_CHARGE_SIGN:
                    //延期——工作负责人签名
                    this.$set(this.sign.delayChargeSign, 'file', data);
                    this.sign.delayChargeSign.signatureName = '延期——工作负责人签名';
                    this.dataForm.data.delayList[0].delayChargeSign = this.sign.delayChargeSign.file.id;
                    break;
                default:
            }
        },
        // 驳回步骤提交
        rejectSubmit() {
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error'
                });
                return;
            }
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: this.dataForm.definitionCode,
                        instanceId: this.dataForm.instanceId,
                        nodeCode: this.dataForm.nodeCode,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data: this.dataForm.data,
                        user: this.dataForm.user,
                        roles: this.dataForm.roles,
                        nextUsers: [this.currentRow.handler],
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closeForm(2);
                    });
            });
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        initNodeCodeValue() {
            //第一种
            if (this.dataForm.data.type === 'elec_factory_work_ticket_1') {
                this.NODE_CODE_VALUE.S1 = 'elec_factory_work_ticket_1_step_1';
                this.NODE_CODE_VALUE.S2 = 'elec_factory_work_ticket_1_step_2';
                this.NODE_CODE_VALUE.S3 = 'elec_factory_work_ticket_1_step_3';
                this.NODE_CODE_VALUE.S4 = 'elec_factory_work_ticket_1_step_4';
                this.NODE_CODE_VALUE.S5 = 'elec_factory_work_ticket_1_step_5';
                //this.NODE_CODE_VALUE.S6 = 'elec_factory_work_ticket_1_step_6';
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_2') {
                //第二种
                this.NODE_CODE_VALUE.S1 = 'elec_factory_work_ticket_2_step_1';
                this.NODE_CODE_VALUE.S2 = 'elec_factory_work_ticket_2_step_2';
                this.NODE_CODE_VALUE.S3 = 'elec_factory_work_ticket_2_step_3';
                this.NODE_CODE_VALUE.S4 = 'elec_factory_work_ticket_2_step_4';
                this.NODE_CODE_VALUE.S5 = 'elec_factory_work_ticket_2_step_5';
                //this.NODE_CODE_VALUE.S6 = 'elec_factory_work_ticket_2_step_6';
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_3') {
                //第二种
                this.NODE_CODE_VALUE.S1 = 'elec_factory_work_ticket_3_step_1';
                this.NODE_CODE_VALUE.S2 = 'elec_factory_work_ticket_3_step_2';
                this.NODE_CODE_VALUE.S3 = 'elec_factory_work_ticket_3_step_3';
                this.NODE_CODE_VALUE.S4 = 'elec_factory_work_ticket_3_step_4';
                this.NODE_CODE_VALUE.S5 = 'elec_factory_work_ticket_3_step_5';
                //this.NODE_CODE_VALUE.S6 = 'elec_factory_work_ticket_3_step_6';
            }
        },
        initNodeCodeMap() {
            this.nodeCodeMap = new Map();
            //第一种
            if (this.dataForm.data.type === 'elec_factory_work_ticket_1') {
                this.nodeCodeMap.set('elec_factory_work_ticket_1_step_1', 1);
                this.nodeCodeMap.set('elec_factory_work_ticket_1_step_2', 2);
                this.nodeCodeMap.set('elec_factory_work_ticket_1_step_3', 3);
                this.nodeCodeMap.set('elec_factory_work_ticket_1_step_4', 4);
                this.nodeCodeMap.set('elec_factory_work_ticket_1_step_5', 5);
                //this.nodeCodeMap.set('elec_factory_work_ticket_1_step_6', 6);
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_2') {
                //第二种
                this.nodeCodeMap.set('elec_factory_work_ticket_2_step_1', 1);
                this.nodeCodeMap.set('elec_factory_work_ticket_2_step_2', 2);
                this.nodeCodeMap.set('elec_factory_work_ticket_2_step_3', 3);
                this.nodeCodeMap.set('elec_factory_work_ticket_2_step_4', 4);
                this.nodeCodeMap.set('elec_factory_work_ticket_2_step_5', 5);
                //this.nodeCodeMap.set('elec_factory_work_ticket_2_step_6', 6);
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_3') {
                //第三种
                this.nodeCodeMap.set('elec_factory_work_ticket_3_step_1', 1);
                this.nodeCodeMap.set('elec_factory_work_ticket_3_step_2', 2);
                this.nodeCodeMap.set('elec_factory_work_ticket_3_step_3', 3);
                this.nodeCodeMap.set('elec_factory_work_ticket_3_step_4', 4);
                this.nodeCodeMap.set('elec_factory_work_ticket_3_step_5', 5);
                //this.nodeCodeMap.set('elec_factory_work_ticket_3_step_6', 6);
            }
        },
        dataExport() {
            let data = {
                type: this.dataForm.definitionCode,
                ...this.dataForm.data,
            };
            this.changeExportDataTime(data, 'planBeginTime');
            this.changeExportDataTime(data, 'planEndTime');
            this.changeExportDataTime(data, 'signIssuerSignTime');
            this.changeExportDataTime(data, 'signCountersignSignTime');
            this.changeExportDataTime(data, 'getSignTime');
            this.changeExportDataTime(data, 'getOnDutyChargeSignTime');
            this.changeExportDataTime(data, 'permitSignTime');
            this.changeExportDataTime(data, 'safeWorkTeamSignaTime');
            this.changeExportDataTime(data, 'chargeSignTime');
            this.changeExportDataTime(data, 'delayDelayTerm');
            this.changeExportDataTime(data, 'delaySignTime');
            this.changeExportDataTime(data, 'finishWorkSignTime');
            this.changeExportDataTime(data, 'finishPermitSignTime');
            this.changeExportDataTime(data, 'hqQfReceiverTime');
            this.changeExportDataTime(data, 'hqReceiverTime');
            this.changeExportDataTime(data, 'qfReceiverTime');
            this.changeExportDataTime(data, 'jsReceiverTime');

            fileDownload('/business/workFactorys/download', {method: 'POST', data});
        },
        changeExportDataTime(data, name) {
            if (data[name] !== undefined && data[name] !== null && data[name] !== '') {
                data[name] = this.$dayjs(data[name]).format('YYYY年MM月DD日HH时mm分');
            }
        },
        //自动补全名称
        /*handleName() {
            if (this.isAddName) {
                return;
            }
            let addName = '';
            if (this.dataForm.data.type === 'elec_factory_work_ticket_1') {
                addName = '厂站第一种工作票';
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_2') {
                addName = '厂站第二种工作票';
            } else if (this.dataForm.data.type === 'elec_factory_work_ticket_3') {
                addName = '厂站第三种工作票';
            }
            this.dataForm.data.name += addName;
            this.isAddName = true;
        },*/
        //设置会签、签发、接收时间
        setReceiver(type) {
            if (type === 'hqQfReceiver') {
                this.dataForm.data.hqQfReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            } else if (type === 'hqReceiver') {
                this.dataForm.data.hqReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            } else if (type === 'qfReceiver') {
                this.dataForm.data.qfReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            } else if (type === 'jsReceiver') {
                this.dataForm.data.jsReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            }
        },
        radioChange(data){
            this.isHqinput = false;
            this.isQfinput = false;
            if(data==="0"){
                this.isHqinput = true;
            }else{
                this.isQfinput = true;
            }
        }
    },
    mounted() {
        // 发计部人员 昆自运维
        let orgCodes = ['DZFJ', 'WB01'];
        this.$client.queryUserByOrgCode(orgCodes).then(data => {
            this.userList = data.data;
        });
        // 昆自运维
        let orgCodes1 = ['WB01'];
        this.$client.queryUserByOrgCode(orgCodes1).then(data => {
            this.userList1 = data.data;
        });
    },
    computed: {
        //是否可见
        getVisible() {
            return function(type) {
                if (this.isShowAll)return true;
                let b = false;
                //1、新建时只展示第一部分数据
                //2、详情页只需要展示已完成内容
                //3、处理页需要展示下一环节填写内容
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    // v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) : this.nodeCodeMap.get(nextNodeCode);
                    v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) - 1 : this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        //待签发
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        //待签发
                        b = v >= tv && this.dataForm.data.isSign === '0';
                        break;
                    case this.NODE_CODE_VALUE.S3:
                        //待接收
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        //待许可
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        //执行中
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S6:
                        //已办理
                        b = v >= tv;
                        break;
                    default:
                }
                return b;
            };
        },
        //是否可编辑
        getDisable() {
            return function(type) {
                if (this.dataForm.isInfo) {
                    //详情时不可编辑
                    return true;
                }
                let b = false;
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    // v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) : this.nodeCodeMap.get(nextNodeCode);
                    v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) - 1 : this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        //待签发
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        //待签发
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S3:
                        //待接收
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        //待许可
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        //执行中
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S6:
                        //已办理
                        b = v !== tv;
                        break;
                    default:
                }
                return b;
            };
        },
        //签名
        signImg() {
            return function(fId) {
                if (fId) {
                    return `${this.$client.serverUrl}files/${fId}?token=${this.$store.state.token}`;
                }
                return '';
            };
        },
    },
};
</script>
<style lang="less" scoped="">
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin:  0 !important;
    }
    .card-margin {
        margin-bottom: 20px;
    }

    .el-textarea .el-input__count {
        background: rgb(0 0 0 / 10%);;
    }

    .factory_name:focus {
        outline: none;
    }

    .factory_name {
        margin-bottom: 4px;
        width: 360px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #ffffff;
        background-color: transparent;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
    }

    .signature {
        /* width: 100%; */
        height: auto;
        margin-top: 20px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: auto;
    }

    // /deep/ .el-textarea__inner {
    //     color: white;
    // }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner{
        border-color: #235eff;
        background: #235eff;
    }

 
</style>
