<template>
    <div>
        <div class="form-table">
            <el-card class="card-pub-notice card-ext-center-title">
                <el-form ref="form" size="mini" label-position="top">
                    <div style="text-align: center">
                    <span style="font-size: 18px">
                        <input
                            class="factory_name"
                            v-model="dataForm.data.name"
                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        />{{ nameType }}
                    </span>
                    </div>
                    <div slot="header">
                        <span>{{ row.processDefinitionName }}</span>
                    </div>
                    <span>编号：{{ dataForm.data.code }}</span>
                    <!-- 基本信息 -->
                    <el-card class="card-pub-notice card-ext-center-title">
                        <div>
                            <ex-card headerName="基本信息填写" class="card-margin">
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="工作负责人（监护人）">
                                            <el-input v-model="dataForm.data.chargeName"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="单位和班组">
                                            <el-input v-model="dataForm.data.teamName"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="工作负责人及工作班人员总人数">
                                            <el-input type="number" class="num-rule"
                                                      v-model="dataForm.data.personCount"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="计划工作时间（开始）">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.planBeginTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="计划工作时间（结束）">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.planEndTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="是否办理分组工作派工单">
                                            <el-radio-group
                                                v-model="dataForm.data.isSendWorkOrder"
                                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                @change="chooseRadio"
                                                size="small">
                                                <el-radio label="1">是</el-radio>
                                                <el-radio label="0">否</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="工单总数">
                                            <el-input type="number" class="num-rule"
                                                      v-model="dataForm.data.workOrderCount"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1) || !isShowWorkOrderCount"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作班人员（不包括工作负责人）">
                                            <el-input
                                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                type="textarea"
                                                rows="3"
                                                v-model="dataForm.data.teamPerson"
                                                placeholder="请输入工作班人员"
                                                maxlength="500"
                                                show-word-limit
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作任务">
                                            <el-input
                                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                type="textarea"
                                                rows="3"
                                                v-model="dataForm.data.task"
                                                placeholder="请输入工作任务"
                                                maxlength="500"
                                                show-word-limit
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="停电线路名称">
                                            <el-input
                                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                type="textarea"
                                                rows="3"
                                                v-model="dataForm.data.blackoutLine"
                                                placeholder="停电线路名称"
                                                maxlength="500"
                                                show-word-limit
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作地段">
                                            <el-input
                                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                                type="textarea"
                                                rows="3"
                                                v-model="dataForm.data.workLocation"
                                                placeholder="请输入工作地点"
                                                maxlength="500"
                                                show-word-limit
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 安全措施 -->
                    <!--第一种-->
                    <el-card v-if="dataForm.data.type === 'elec_line_work_ticket_1'"
                             class="card-pub-notice card-ext-center-title">
                        <div>
                            <ex-card headerName="工作要求的安全措施（必要时可附页绘图说明）" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="应拉断路器（开关）和隔离开关（刀闸）（厂站名及双重名称或编号）：">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="应拉断路器（开关）和隔离开关（刀闸）（厂站名及双重名称或编号）"
                                                      v-model="dataForm.data.safeSwitch"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="应合的接地刀闸（注明双重编号）或应装的接地线（装设地点）：">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="应合的接地刀闸（注明双重编号）或应装的接地线（装设地点）："
                                                      v-model="dataForm.data.safeGroundWire"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="应设遮栏、应挂标示牌（注明位置）：">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="应设遮栏、应挂标示牌（注明位置）："
                                                      v-model="dataForm.data.safeSignboard"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="其他安全措施和注意事项">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="其他安全措施和注意事项"
                                                      v-model="dataForm.data.safeNote"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>
                    <!--第二种-->
                    <el-card v-else class="card-pub-notice card-ext-center-title">
                        <div>
                            <ex-card headerName="工作要求的安全措施" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="应采取的安全措施（停用线路重合闸装置、退出再启动功能等）：">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.safeMeasure"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="其他安全措施和注意事项">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.safeNote"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S1)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 应装设的接地线 -->
                    <el-card v-if="dataForm.data.type === 'elec_line_work_ticket_1'"
                             class="card-pub-notice card-ext-center-title">
                        <div>
                            <ex-card headerName="应装设的接地线" class="card-margin" style="margin-top: -20px">
                                <ex-edit-table-form
                                    :rules="rules.switchRules"
                                    size="mini"
                                    :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: dataForm.data.groundWireList,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                    table-style="width: 100%"
                                    class="task-book-info"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                >
                                    <template slot-scope="editScope">
                                        <el-table-column label="设备信息">
                                            <el-table-column align="center" label="线路名称及杆号">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.name"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.name }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="接地线编号">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.code"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.code }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="操作" type="action" width="160"
                                                             fixed="right">
                                                <template slot-scope="scope">
                                                    <el-button
                                                        :data-delindex="scope"
                                                        @click="deleteGroundWire(scope)"
                                                        type="text"
                                                        v-if="!getDisable(NODE_CODE_VALUE.S1)"
                                                    >删除
                                                    </el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                    </template>
                                </ex-edit-table-form>
                                <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px"
                                     v-if="!getDisable(NODE_CODE_VALUE.S1)">
                                    <i class="el-icon-circle-plus" title="添加开关" @click="addTableData(1)"></i>
                                </div>
                            </ex-card>
                        </div>
                    </el-card>

                    <!--选择会签/签发-->
                    <el-card class="card-pub-notice card-ext-center-title">
                        <div>
                            <ex-card headerName="选择会签/签发" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="请选择会签/签发">
                                            <el-radio-group
                                                v-model="dataForm.data.isSign"
                                                :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                                @change="radioChange"
                                                size="small">
                                                <el-radio label="0">会签</el-radio>
                                                <el-radio label="1">签发</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" v-if="isHqinput">
                                        <el-form-item label="选择会签人">
                                            <el-select v-model="dataForm.data.hqReceiver"
                                                       filterable placeholder="请选择"
                                                       @change="setReceiver('hqReceiver')"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                            >
                                                <el-option
                                                    v-for="item in userList"
                                                    :key="item.username"
                                                    :value="item.username"
                                                    :label="item.realName"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" v-if="isQfinput">
                                        <el-form-item label="选择签发人">
                                            <el-select v-model="dataForm.data.qfReceiver"
                                                       filterable placeholder="请选择"
                                                       @change="setReceiver('qfReceiver')"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S1) ? true : false"
                                            >
                                                <el-option
                                                    v-for="item in userList"
                                                    :key="item.username"
                                                    :value="item.username"
                                                    :label="item.realName"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 签发人 -->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S2)">
                        <div>
                            <ex-card headerName="签发人" class="card-margin" style="margin-top: -20px">
                                <!--<el-row :gutter="24">
                                    <el-col :span="6">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作票会签人签名
                                            <el-button @click="openSignDlg(SIGN_TYPE.SIGN_COUNTERSIGN_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S2)">
                                                点击进入电子签章
                                            </el-button>
                                        </div>
                                        <img
                                            :src="signImg(dataForm.data.signCountersignSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        />
                                    </el-col>
                                </el-row>-->
                                <el-row :gutter="24">
                                    <!--<el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-input v-model="dataForm.data.signCountersignSignTime" disabled></el-input>
                                        </el-form-item>
                                    </el-col>-->
                                    <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S2)">
                                        <el-form-item label="选择签发人">
                                            <el-select v-model="dataForm.data.qfReceiver"
                                                       filterable placeholder="请选择"
                                                       @change="setReceiver('qfReceiver')"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S2) ? true : false"
                                            >
                                                <el-option
                                                    v-for="item in userList"
                                                    :key="item.username"
                                                    :value="item.username"
                                                    :label="item.realName"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>
                    <!--接收人-->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S3)">
                        <div>
                            <ex-card headerName="接收人" class="card-margin" style="margin-top: -20px">
                                <!--<el-row :gutter="24">
                                    <el-col :span="6">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作票签发人签名
                                            <el-button @click="openSignDlg(SIGN_TYPE.SIGN_ISSUER_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S3)">
                                                点击进入电子签章
                                            </el-button>
                                        </div>
                                        <img
                                                :src="signImg(dataForm.data.signIssuerSign)"
                                                style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        />
                                    </el-col>
                                </el-row>-->
                                <el-row :gutter="24">
                                    <!--<el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-input v-model="dataForm.data.signIssuerSignTime" disabled></el-input>
                                        </el-form-item>
                                    </el-col>-->
                                    <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S3)">
                                        <el-form-item label="选择接收人">
                                            <el-select v-model="dataForm.data.jsReceiver"
                                                       filterable clearable placeholder="请选择"
                                                       @change="setReceiver('jsReceiver')"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S3) ? true : false"
                                            >
                                                <el-option
                                                    v-for="item in userList1"
                                                    :key="item.username"
                                                    :value="item.username"
                                                    :label="item.realName"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 接收 -->
                    <!--<el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S4)">
                        <div>
                            <ex-card headerName="接收" class="card-margin" style="margin-top: -20px">
                                &lt;!&ndash;<el-row :gutter="24">
                                    <el-col :span="24">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>值班负责人签名
                                            <el-button @click="openSignDlg(SIGN_TYPE.GET_ON_DUTY_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S4)">
                                                点击进入电子签章
                                            </el-button>
                                        </div>
                                        <img
                                            :src="signImg(dataForm.data.getOnDutyChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        />
                                    </el-col>
                                </el-row>&ndash;&gt;
                                <el-row :gutter="24">
                                    &lt;!&ndash;<el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-input v-model="dataForm.data.getOnDutyChargeSignTime" disabled></el-input>
                                        </el-form-item>
                                    </el-col>&ndash;&gt;
                                    <el-col :span="6" v-if="getVisible(NODE_CODE_VALUE.S4)">
                                        <el-form-item label="选择接收人">
                                        <el-select v-model="dataForm.data.jsReceiver"
                                                   filterable clearable placeholder="请选择"
                                                   :disabled="getDisable(NODE_CODE_VALUE.S4) ? true : false"
                                        >
                                                    <el-option
                                                        v-for="item in userList1"
                                                        :key="item.username"
                                                        :value="item.username"
                                                        :label="item.realName"
                                                    ></el-option>
                                                </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>-->

                    <!-- 工作许可 -->
                    <!--第一种-->
                    <el-card class="card-pub-notice card-ext-center-title"
                            v-if="getVisible(NODE_CODE_VALUE.S4) && dataForm.data.type === 'elec_line_work_ticket_1'">
                        <div>
                            <ex-card headerName="工作许可" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作许可人负责的本工作票 “工作要求的安全措施”栏所述措施已经落实" style="display:online">
                                            <el-checkbox v-model="dataForm.data.permitMeetRequirement"
                                                         :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="保留或邻近的带电线路、设备">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="保留或邻近的带电线路、设备"
                                                      v-model="dataForm.data.permitRetainEquipment"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="其他安全注意事项">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="其他安全注意事项"
                                                      v-model="dataForm.data.permitNote"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" >
                                    <el-col :span="24">
                                        <el-form-item label="许可方式">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.permitType"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" >
                                    <el-col :span="24" >
                                        <el-form-item label="指定监护人">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.guarder"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;"></span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.PERMIT_WORK_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.permitWorkPermitSign" placeholder="请输入工作许可人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.permitWorkPermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;"></span>工作负责人
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.PERMIT_WORK_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.permitWorkChargeSign" placeholder="请输入工作负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.permitWorkChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;"></span>监护人
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.GUARDER_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.guarderSign" placeholder="请输入监护人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.guarderSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.permitSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>
                    <!--第二种-->
                    <el-card class="card-pub-notice card-ext-center-title"
                            v-if="getVisible(NODE_CODE_VALUE.S4) && dataForm.data.type === 'elec_line_work_ticket_2'">
                        <div>
                            <ex-card headerName="工作许可" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作许可人负责的本工作票 “工作要求的安全措施”栏所述措施已经落实">
                                            <el-checkbox :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="其他安全注意事项">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.permitNote"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S4)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>调度值班员（运维人员）
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.PERMIT_DISPATCH_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.permitDispatchSign" placeholder="请输入调度值班员（运维人员）签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.permitDispatchSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24"  v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作负责人
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.PERMIT_WORK_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.permitWorkChargeSign" placeholder="请输入工作负责人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.permitWorkChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" v-if="getVisible(NODE_CODE_VALUE.S5)">
                                    <el-col :span="6">
                                        <el-form-item label="通知（许可）的方式">
                                            <el-input v-model="dataForm.data.permitType"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.permitSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 安全交代 -->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <div>
                            <ex-card headerName="安全交代" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item
                                            label="工作班人员确认工作负责人所交代布置的工作任务、安全措施和作业安全注意事项。"
                                        >
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作班人员分组负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.SAFE_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.safeChargeSign" placeholder="请输入工作班人员分组负责人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.safeChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.safeSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 工作间断 -->
                    <el-card class="card-pub-notice card-ext-center-title"
                             v-if="getVisible(NODE_CODE_VALUE.S5) && dataForm.data.type === 'elec_line_work_ticket_1'">
                        <div>
                            <ex-card headerName="工作间断" class="card-margin" style="margin-top: -20px">
                                <ex-edit-table-form
                                    :rules="rules.breakRules"
                                    size="mini"
                                    :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: dataForm.data.breakList,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                    table-style="width: 100%"
                                    class="task-book-info"
                                    :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                >
                                    <template slot-scope="editScope">
                                        <el-table-column label="间断信息">
                                            <el-table-column align="center" label="工作间断时间">
                                                <template slot-scope="{row}">
                                                    <el-form-item v-if="row === editScope.editingRow">
                                                        <el-date-picker
                                                            style="width: 220px"
                                                            v-model="row.breakTime"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                                        >
                                                        </el-date-picker>
                                                    </el-form-item>
                                                    <span v-else>{{ row.breakTime }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="间断工作许可人">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.breakPermit"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.breakPermit }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="间断工作负责人">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.breakCharge"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.breakCharge }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="间断方式">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.breakType"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.breakType }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="工作开始时间">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-date-picker
                                                            style="width: 220px"
                                                            v-model="scope.row.workTime"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                                        >
                                                        </el-date-picker>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.workTime }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="开始工作许可人">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.workPermit"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.workPermit }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="开始工作负责人">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.workCharge"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.workCharge }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="开始方式">
                                                <template slot-scope="scope">
                                                    <el-form-item v-if="scope.row === editScope.editingRow">
                                                        <el-input v-model="scope.row.workType"></el-input>
                                                    </el-form-item>
                                                    <span v-else>{{ scope.row.workType }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="操作" type="action" width="120"
                                                             fixed="right">
                                                <template slot-scope="scope">
                                                    <el-button
                                                        :data-delindex="scope"
                                                        @click="deleteBreak(scope)"
                                                        type="text"
                                                        v-if="!getDisable(NODE_CODE_VALUE.S5)"
                                                    >删除
                                                    </el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                    </template>
                                </ex-edit-table-form>
                                <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px"
                                     v-if="!getDisable(NODE_CODE_VALUE.S5)">
                                    <i class="el-icon-circle-plus" title="添加间断信息" @click="addTableData(2)"></i>
                                </div>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 工作变更 -->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <div>
                            <ex-card headerName="工作变更" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作任务"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="不需变更安全措施下增加的工作内容">
                                            <el-input
                                                :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                type="textarea"
                                                rows="3"
                                                v-model="dataForm.data.changeList[0].taskNewTask"
                                                placeholder="请输入工作班人员"
                                                maxlength="500"
                                                show-word-limit
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_TASK_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].taskChargeSign" placeholder="请输入工作负责人签名签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].taskChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_TASK_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].taskPermitSign" placeholder="请输入工作许可人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].taskPermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.changeList[0].taskSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作负责人"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作票签发人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_ISSUER_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].chargeIssuerSign" placeholder="请输入工作票签发人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].chargeIssuerSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>原工作负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_PRE_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].chargePreChargeSign" placeholder="请输入原工作负责人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].chargePreChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>现工作负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_CURRENT_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].chargeCurrentChargeSign" placeholder="请输入现工作负责人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].chargeCurrentChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.CHANGE_CHARGE_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].chargePermitSign" placeholder="请输入工作许可人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.changeList[0].chargePermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.changeList[0].chargeSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="工作班人员"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <ex-edit-table-form
                                        :rules="rules.changeItemRules"
                                        size="mini"
                                        :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: dataForm.data.changeList[0].itemList,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                        table-style="width: 100%"
                                        class="task-book-info"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    >
                                        <template slot-scope="editScope">
                                            <el-table-column label="变更信息">
                                                <el-table-column align="center" label="变更情况">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-input v-model="scope.row.content"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.content }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="工作许可人/签发人">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-input v-model="scope.row.sign"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.sign }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="工作负责人">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-input v-model="scope.row.charge"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.charge }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="变更时间">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-date-picker
                                                                style="width: 220px"
                                                                v-model="scope.row.changeTime"
                                                                type="datetime"
                                                                placeholder="选择日期"
                                                                format="yyyy-MM-dd HH:mm"
                                                                value-format="yyyy-MM-dd HH:mm"
                                                            >
                                                            </el-date-picker>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.changeTime }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    align="center"
                                                    label="操作"
                                                    type="action"
                                                    width="120"
                                                    fixed="right"
                                                >
                                                    <template slot-scope="scope">
                                                        <el-button
                                                            :data-delindex="scope"
                                                            @click="deleteChangeItem(scope)"
                                                            type="text"
                                                            v-if="!getDisable(NODE_CODE_VALUE.S5)"
                                                        >删除
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                        </template>
                                    </ex-edit-table-form>
                                    <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px"
                                         v-if="!getDisable(NODE_CODE_VALUE.S5)">
                                        <i class="el-icon-circle-plus" title="添加变更信息" @click="addTableData(3)"></i>
                                    </div>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!-- 工作延期 -->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <div>
                            <ex-card headerName="工作延期" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="有效期延长至">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.delayList[0].delayDelayTerm"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="申请方式">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.delayList[0].delayApplyType"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.DELAY_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].delayPermitSign" placeholder="请输入工作许可人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.delayList[0].delayPermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.DELAY_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.changeList[0].delayChargeSign" placeholder="请输入工作负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.delayList[0].delayChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.delayList[0].delaySignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!--工作票的终结-->
                    <!--第一种-->
                    <el-card class="card-pub-notice card-ext-center-title"
                             v-if=" getVisible(NODE_CODE_VALUE.S5) && dataForm.data.type === 'elec_line_work_ticket_1'">
                        <div>
                            <ex-card headerName="工作票的终结" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="作业终结"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item
                                            label="全部作业已结束，线路（或配电设备）上所装设的接地线和使用的个人保安线已全部拆除，工作人员已全部撤离，材料工具已清 理完毕，已恢复作业开始前状态"
                                        >
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="线路（或配电设备）上所装设的接地线数量">
                                            <el-input type="number" class="num-rule"
                                                      v-model="dataForm.data.finishWorkEquipmentCount"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="终结方式">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.finishWorkFinishType"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_WORK_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishWorkChargeSign" placeholder="请输入工作负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishWorkChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_WORK_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishWorkPermitSign" placeholder="请输入工作许可人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishWorkPermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.finishWorkSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="许可人措施终结"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="临时遮栏已拆除，标示牌已取下，常设遮栏已恢复。"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>工作许可人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_PERMIT_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishPermitSign" placeholder="请输入工作许可人签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishPermitSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.permitSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="汇报调度"></el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="未拉开接地刀闸双重名称或编号">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.finishDispatchSwitch"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="未拆除接地线装设地点及编号">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      v-model="dataForm.data.finishDispatchEquipment"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="未拉开接地刀闸双重数量">
                                            <el-input type="number" class="num-rule"
                                                      v-model="dataForm.data.finishDispatchSwitchCount"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="未拆除接地线装设数量">
                                            <el-input type="number" class="num-rule"
                                                      v-model="dataForm.data.finishDispatchEquipmentCount"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>值班负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_DISPATCH_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishDispatchChargeSign" placeholder="请输入值班负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishDispatchChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>值班调度员（姓名）
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_DISPATCH_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishDispatchSign" placeholder="请输入值班调度员（姓名）签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishDispatchSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.finishDispatchSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>
                    <!--第二种-->
                    <el-card class="card-pub-notice card-ext-center-title"
                             v-if=" getVisible(NODE_CODE_VALUE.S5) && dataForm.data.type === 'elec_line_work_ticket_2'">
                        <div>
                            <ex-card headerName="工作票的终结" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="结束时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.finishTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="18">
                                        <el-form-item
                                            label="全部作业结束，检修临时安全措施已拆除，已恢复作业开始前状态，作业人员已全部撤离，材料工具已清理完毕。"
                                        >
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="相关线路重合闸装置、再启动功能可以恢复">
                                            <el-checkbox v-model="dataForm.data.finishCanRecovery"
                                                         :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>接受汇报或通知的调度值班员（运维人员）签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_DISPATCH_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishDispatchSign" placeholder="请输入接受汇报或通知的调度值班员（运维人员）签名" :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishDispatchSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="4">
                                        <div>
                                            <span style="color: #F56C6C;margin-right: 5px;">*</span>值班负责人签名
                                            <!-- <el-button @click="openSignDlg(SIGN_TYPE.FINISH_DISPATCH_CHARGE_SIGN)"
                                                       style="display: inline-block;"
                                                       type="primary"
                                                       :disabled="getDisable(NODE_CODE_VALUE.S5)">
                                                点击进入电子签章
                                            </el-button> -->
                                            <el-input v-model="dataForm.data.finishDispatchChargeSign" placeholder="请输入值班负责人签名"  :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </div>
                                        <br>
                                        <!-- <img
                                            :src="signImg(dataForm.data.finishDispatchChargeSign)"
                                            style="width: 300px;height: auto;margin: 20px 0 20px;"
                                        /> -->
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="终结方式">
                                            <el-input v-model="dataForm.data.finishType"
                                                      :disabled="getDisable(NODE_CODE_VALUE.S5)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="时间">
                                            <el-date-picker style="width: 280px"
                                                            v-model="dataForm.data.finishDispatchSignTime"
                                                            :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                                            type="datetime"
                                                            placeholder="选择日期"
                                                            format="yyyy-MM-dd HH:mm"
                                                            value-format="yyyy-MM-dd HH:mm"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>

                    <!--备注-->
                    <el-card class="card-pub-notice card-ext-center-title" v-if="getVisible(NODE_CODE_VALUE.S5)">
                        <div>
                            <ex-card headerName="备注" class="card-margin" style="margin-top: -20px">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="备注">
                                            <el-input type="textarea"
                                                      rows="3"
                                                      placeholder="备注"
                                                      v-model="dataForm.data.remark"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </ex-card>
                        </div>
                    </el-card>
                    <!-- <sign ref="ref_elec_sign" class="sign_so" @success="signCallback"></sign> -->
                    <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
                        <el-table
                            :data="dataForm.rejectNodes"
                            border
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            style="width: 100%; height: 200px;  overflow-y: auto !important;"
                        >
                            <el-table-column align="center" fixed prop="name" :show-overflow-tooltip="true"
                                             label="步骤名称">
                            </el-table-column>
                        </el-table>
                        <div style="text-align:center;margin-top:10px;">
                            <el-button type="primary" @click="rejectSubmit">驳回</el-button>
                        </div>
                    </el-dialog>
                </el-form>
            </el-card>
        </div>
        <div class="submit-btn-group button-fixed" v-if="dataForm.curNodeCode === NODE_CODE_VALUE.S3">
            <template v-if="!dataForm.isInfo">
                <el-button @click="save('saveAndNext')" type="primary">{{submitButtonText}}</el-button>
                <el-button @click="dialogVisible = true" type="primary">驳回</el-button>
            </template>
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="dataExport" type="danger">导出</el-button>
        </div>
        <div class="submit-btn-group button-fixed" v-else>
            <template v-if="!dataForm.isInfo">
                <el-button @click="save('save')" type="primary">{{saveButtonText}}</el-button>
                <el-button @click="save('saveAndNext')" type="primary">{{submitButtonText}}</el-button>
                <el-button @click="dialogVisible = true" type="primary">驳回</el-button>
            </template>
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="dataExport" type="danger">导出</el-button>
        </div>
    </div>
</template>

<script>
import Sign from '@/components/Sign/index';
import dayjs from 'dayjs';
import {fileDownload} from '@/utils';

export default {
    name: 'WorkTicketLine',
    components: {
        Sign
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            row: {},
            nameType: '',
            nodeCodeMap: new Map(), //正在处理的环节编码权值，用于判断显示/隐藏及编辑
            dataForm: {
                instanceId: null, //实例id
                definitionCode: '', //流程编码
                nodeCode: '', //下一环节code
                operationType: '', //操作类型 save saveAndNext reject
                jumpNodeCode: '', //跳转环节
                user: '',
                roles: [],
                nextUsers: null, //下一环节处理人
                nextRoles: null, //下一角色
                curNodeCode: '', //当前环节编码
                isInfo: '', //是否是详情
                isAddName: false, //是否自动补全过名称
                extraProp: { //查询条件
                    code: ''
                },
                data: {
                    id: '',
                    name: '', //线路名称
                    code: '', //编码
                    type: '', //类型（1-第一种 2-第二种）
                    chargeName: '', //工作负责人
                    teamName: '', //单位、班组名称
                    personCount: '', //工作负责人及工作班总人数
                    planBeginTime: '', //计划工时开始
                    planEndTime: '', //计划工时结束
                    isSendWorkOrder: '', //是否派工单
                    workOrderCount: '', //工单总数
                    teamPerson: '', //工作班人员（不包括工作负责人）
                    task: '', //工作任务
                    blackoutLine: '', //停电线路
                    workLocation: '', //工作地段
                    safeSwitch: '', //安全措施——断路器、隔离开关
                    safeGroundWire: '', //安全措施——接地刀闸、接地线
                    safeSignboard: '', //安全措施——遮拦、标示牌
                    safeMeasure: '', //安全措施——应采取的安全措施
                    safeNote: '', //安全措施——其他注意事项
                    signIssuerSign: '', //签发——工作票签发人签名
                    signIssuerSignTime: '', //签发——工作票签发人签名时间
                    signCountersignSign: '', //签发——工作票会签人签名
                    signCountersignSignTime: '', //签发——工作票会签人签名时间
                    getOnDutyChargeSign: '', //接收——值班负责人签名
                    getOnDutyChargeSignTime: '', //接收——值班负责人签名时间
                    permitMeetRequirement: '', //工作许可——是否落实工作要求
                    permitRetainEquipment: '', //工作许可——保留线路、设备
                    permitNote: '', //工作许可——其他注意事项
                    permitType: '', //工作许可——许可方式
                    permitWorkPermitSign: '', //工作许可——工作许可人签名
                    permitWorkChargeSign: '', //工作许可——工作负责人签名
                    permitDispatchSign: '', //工作许可——调度值班员（运维人员）签名
                    permitNotifyType: '', //工作许可——通知方式
                    permitSignTime: '', //工作许可——签名时间
                    guarder: '', //监护人
                    guarderSign: '', //监护人签名
                    safeChargeSign: '', //安全交代——工作班人员分组负责人签名
                    safeSignTime: '', //安全交代——签名时间
                    finishWorkEquipmentCount: '', //工作票的终结——作业终结——线路上装设接地线数量
                    finishWorkChargeSign: '', //工作票的终结——作业终结——工作负责人签名
                    finishWorkPermitSign: '', //工作票的终结——作业终结——工作许可人签名
                    finishWorkFinishType: '', //工作票的终结——作业终结——终结方式
                    finishWorkSignTime: '', //工作票的终结——作业终结——签名时间
                    finishPermitSign: '', //工作票的终结——许可人措施终结——许可人签名
                    finishPermitSignTime: '', //工作票的终结——许可人措施终结——签名时间
                    finishDispatchSwitch: '', //工作票的终结——汇报调度——未拉开接地刀闸名称或编号
                    finishDispatchSwitchCount: '', //工作票的终结——汇报调度——未拉开接地刀闸数量
                    finishDispatchEquipment: '', //工作票的终结——汇报调度——未拆除接地线地点及编号
                    finishDispatchEquipmentCount: '', //工作票的终结——汇报调度——未拆除接地线数量
                    finishDispatchChargeSign: '', //工作票的终结——汇报调度——值班负责人签名
                    finishDispatchSign: '', //工作票的终结——汇报调度——值班调度员签名
                    finishDispatchSignTime: '', //工作票的终结——汇报调度——签名时间
                    finishTime: '', //工作票的终结——终结时间
                    finishCanRecovery: '', //工作票的终结——相关线路、设备是否可以恢复
                    finishType: '', //工作票的终结——终结方式
                    remark: '', //备注
                    startUser: '', //发起人
                    startTime: '', //发起时间
                    createUser: '', //创建人
                    isFirstSubmit: true, //是否是第一次提交，用于处理第一人保存，第二人提交的情况

                    groundWireList: [], //应装设的接地线
                    breakList: [], //工作间断
                    workflowCreateBy: '', //发起人
                    workflowCreateTime: '', //发起时间
                    changeList: [{ //变更
                        id: '',
                        workTicketType: '', //工作票类型
                        workTicketId: '', //工作票id
                        taskNewTask: '', //工作任务——变更内容
                        taskChargeSign: '', //工作任务——工作负责人签名
                        taskPermitSign: '', //工作任务——工作许可人人签名
                        taskSignTime: '', //工作任务——签名时间
                        chargeIssuerSign: '', //工作负责人——工作票签发人签名
                        chargePreChargeSign: '', //工作负责人——原工作负责人签名
                        chargeCurrentChargeSign: '', //工作负责人——现工作负责人签名
                        chargePermitSign: '', //工作负责人——工作许可人签名
                        chargeSignTime: '', //工作负责人——签名时间
                        itemList: [] //变更项
                    }],
                    delayList: [{ //延期
                        id: '',
                        workTicketType: '', //工作票类型
                        workTicketId: '', //工作票id
                        delayDelayTerm: '', //延期后有效期
                        delayPermitSign: '', //工作许可人签名
                        delayChargeSign: '', //工作负责人签名
                        delayApplyType: '', //申请方式
                        delaySignTime: '' //签名时间
                    }],
                    saveNotSubmit: null, //保存未提交
                    isSign: null, //是否会签 0-会签 1-签发,
                    hqQfReceiver: '', //会签/签发
                    hqReceiver: '', //会签人
                    qfReceiver: '', //签发人
                    jsReceiver: '' //接收人
                }
            },
            //环节编码权值
            NODE_CODE_VALUE: {},
            //签名
            SIGN_TYPE: {
                SIGN_ISSUER_SIGN: 'signIssuerSign', //签发——工作票签发人签名
                SIGN_COUNTERSIGN_SIGN: 'signCountersignSign', //签发——工作票会签人签名
                GET_ON_DUTY_CHARGE_SIGN: 'getOnDutyChargeSign', //接收——值班负责人签名
                PERMIT_WORK_PERMIT_SIGN: 'permitWorkPermitSign', //工作许可——工作许可人签名
                PERMIT_WORK_CHARGE_SIGN: 'permitWorkChargeSign', //工作许可——工作负责人签名
                PERMIT_DISPATCH_SIGN: 'permitDispatchSign', //工作许可——调度值班员（运维人员）签名
                GUARDER_SIGN: 'guarderSign', //监护人签名
                SAFE_CHARGE_SIGN: 'safeChargeSign', //安全交代——工作班人员分组负责人签名
                FINISH_WORK_CHARGE_SIGN: 'finishWorkChargeSign', ////工作票的终结——作业终结——工作负责人签名
                FINISH_WORK_PERMIT_SIGN: 'finishWorkPermitSign', //工作票的终结——作业终结——工作许可人签名
                FINISH_PERMIT_SIGN: 'finishPermitSign', //工作票的终结——许可人措施终结——许可人签名
                FINISH_DISPATCH_CHARGE_SIGN: 'finishDispatchChargeSign', //工作票的终结——汇报调度——值班负责人签名
                FINISH_DISPATCH_SIGN: 'finishDispatchSign', //工作票的终结——汇报调度——值班调度员签名
                CHANGE_TASK_CHARGE_SIGN: 'taskChargeSign', //变更——工作任务——工作负责人签名
                CHANGE_TASK_PERMIT_SIGN: 'taskPermitSign', //变更——工作任务——工作许可人人签名
                CHANGE_CHARGE_ISSUER_SIGN: 'chargeIssuerSign', //变更——工作负责人——工作票签发人签名
                CHANGE_CHARGE_PRE_CHARGE_SIGN: 'chargePreChargeSign', //变更——工作负责人——原工作负责人签名
                CHANGE_CHARGE_CURRENT_CHARGE_SIGN: 'chargeCurrentChargeSign', //变更——工作负责人——现工作负责人签名
                CHANGE_CHARGE_PERMIT_SIGN: 'chargePermitSign', //工作负责人——工作许可人签名
                DELAY_PERMIT_SIGN: 'delayPermitSign', //延期——工作许可人签名
                DELAY_CHARGE_SIGN: 'delayChargeSign' //工作负责人签名
            },
            sign: {
                signIssuerSign: {
                    //签发——工作票签发人签名
                    file: null,
                    signatureAdvice: ''
                },
                signCountersignSign: {
                    //签发——工作票会签人签名
                    file: null,
                    signatureAdvice: ''
                },
                getOnDutyChargeSign: {
                    //接收——值班负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                permitWorkPermitSign: {
                    //工作许可——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                permitDispatchSign: {
                    //工作许可——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                permitWorkChargeSign: {
                    //工作许可——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                guarderSign: {
                    //监护人签名
                    file: null,
                    signatureAdvice: ''
                },
                safeChargeSign: {
                    //安全交代——工作班人员分组负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                finishWorkChargeSign: {
                    //工作票的终结——作业终结——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                finishWorkPermitSign: {
                    //工作票的终结——作业终结——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                finishPermitSign: {
                    //工作票的终结——许可人措施终结——许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                finishDispatchChargeSign: {
                    //工作票的终结——汇报调度——值班负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                finishDispatchSign: {
                    //工作票的终结——汇报调度——值班调度员签名
                    file: null,
                    signatureAdvice: ''
                },
                taskChargeSign: {
                    //变更——工作任务——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                taskPermitSign: {
                    //变更——工作任务——工作许可人人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargeIssuerSign: {
                    //变更——工作负责人——工作票签发人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargePreChargeSign: {
                    //变更——工作负责人——原工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargeCurrentChargeSign: {
                    //变更——工作负责人——现工作负责人签名
                    file: null,
                    signatureAdvice: ''
                },
                chargePermitSign: {
                    //变更——工作负责人——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                delayPermitSign: {
                    //延期——工作许可人签名
                    file: null,
                    signatureAdvice: ''
                },
                delayChargeSign: {
                    //延期——工作负责人签名
                    file: null,
                    signatureAdvice: ''
                }
            },
            //校验
            rules: {
                switchRules: {
                    name: [{required: true, message: '请填写接地线名称', trigger: 'blur'}],
                    code: [{required: true, message: '请填写接地线编号', trigger: 'blur'}]
                },
                breakRules: {
                    breakTime: [{required: true, message: '请填写工作间断时间', trigger: 'blur'}],
                    breakPermit: [{required: true, message: '请填写间断工作许可人', trigger: 'blur'}],
                    breakCharge: [{required: true, message: '请填写间断工作负责人', trigger: 'blur'}],
                    breakType: [{required: true, message: '请填写间断方式', trigger: 'blur'}],
                    workTime: [{required: true, message: '请填写工作开始时间', trigger: 'blur'}],
                    workPermit: [{required: true, message: '请填写开始工作许可人', trigger: 'blur'}],
                    workCharge: [{required: true, message: '请填写开始工作负责人', trigger: 'blur'}],
                    workType: [{required: true, message: '请填写开开始方式', trigger: 'blur'}]
                },
                changeItemRules: {
                    content: [{required: true, message: '变更内容', trigger: 'blur'}],
                    sign: [{required: true, message: '许可人/签发人', trigger: 'blur'}],
                    charge: [{required: true, message: '工作负责人', trigger: 'blur'}],
                    changeTime: [{required: true, message: '变更时间', trigger: 'blur'}]
                }
            },
            //是否显示工单总数
            isShowWorkOrderCount: false,
            dialogVisible: false,
            currentRow: null,
            userList: [],
            userList1: [],
            isShowAll:false,
            isQfinput:false,
            isHqinput:true,
            saveButtonText:"保存",
            submitButtonText:"保存并发布",
        };
    },
    methods: {
        init(item) {
            if (item.name) {
                this.nameType = item.name;
            }
            if (item.definitionCode) {
                if (item.definitionCode === 'elec_line_work_ticket_1') {
                    this.nameType = '线路第一种工作票';
                }
                if (item.definitionCode === 'elec_line_work_ticket_2') {
                    this.nameType = '线路第二种工作票';
                }
            }
            if (item.instanceId) {
                //编辑
                this.dataForm = item;
                //设置工单总数可编辑
                this.isShowWorkOrderCount = this.dataForm.data.isSendWorkOrder === '1';
                if (!this.isShowWorkOrderCount) {
                    this.dataForm.data.workOrderCount = '';
                }
            } else {
                //新建
                //获取用户信息
                this.dataForm.user = this.$store.state.userInfo.username;
                this.dataForm.roles = this.$store.state.userRole;
                //新建——初始化
                this.dataForm.data.type = item.code;
                //获取流程参数
                this.dataForm.definitionCode = item.code || item.definitionCode;
                this.dataForm.operationType = 'save';
                //获取instanceId和nodeCode
                this.$client.workflowInstanceProcess(this.dataForm).then(res => {
                    if (res.code === 0) {
                        this.dataForm.instanceId = res.data.instance.id;
                        this.dataForm.nodeCode = res.data.nextNode.code;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                });
                //发起人
                this.dataForm.data.workflowCreateBy = this.$store.state.userInfo.realName;
                //发起时间
                this.dataForm.data.workflowCreateTime = this.$dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            }
            this.initNodeCodeValue();
            this.initNodeCodeMap();
            this.setReject();
            this.radioChange(item.data.isSign)
            this.isShowAll = item.isShowAll;
            if(this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3){
                this.submitButtonText = "发送"
            }else if(this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4){
                this.submitButtonText = "生成票号并发布"
            }
        },
        async save(type) {
            let msg = this.dataForm.nodeCode === this.NODE_CODE_VALUE.S3 ? '接收人接收' : '确定保存';
            this.dataForm.data.createUser = this.$store.state.userInfo.username;
            let confirm = await this.$confirm(msg, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (confirm !== 'confirm') {
                return;
            }
            let workTicketType = this.isGenCode();
            //设置发起人
            if (type === 'saveAndNext' && this.dataForm.data.isFirstSubmit) {
                this.dataForm.data.isFirstSubmit = false;
                this.dataForm.data.workflowCreateBy = this.$store.state.userInfo.realName;
            }
            if (workTicketType && !this.dataForm.code) {
                //待接收时需要生成code
                this.$client.getNextWorkTicketCode(workTicketType).then((res) => {
                    let code = res.data;
                    this.dataForm.data.code = code;
                    if (!this.dataForm.extraProp) {
                        this.dataForm.extraProp = {};
                    }
                    this.dataForm.extraProp.code = code;
                    this.doSave(type);
                });
            } else {
                this.doSave(type);
            }
        },
        //设置reject首环节
        setReject() {
            if (!this.dataForm.rejectNodes || this.dataForm.rejectNodes.length < 1) {
                return;
            }
            this.dataForm.rejectNodes.forEach(reject => {
                if (reject.node === this.NODE_CODE_VALUE.S1) {
                    return;
                }
            });
        },
        //是否生成code，只有签发后才能生成code
        isGenCode() {
            let workTicketType = '';
            switch (this.dataForm.nodeCode) {
                case 'elec_line_work_ticket_1_step_4':
                    workTicketType = 'X1';
                    break;
                case 'elec_line_work_ticket_2_step_4':
                    workTicketType = 'X2';
                    break;
                case 'elec_factory_work_ticket_1_step_4':
                    workTicketType = 'C1';
                    break;
                case 'elec_factory_work_ticket_2_step_4':
                    workTicketType = 'C2';
                    break;
                case 'elec_factory_work_ticket_3_step_4':
                    workTicketType = 'C3';
                    break;
                default:
            }
            return workTicketType;
        },
        doSave(type) {
            if (this.dataForm.data.planBeginTime && this.dataForm.data.planEndTime && this.dataForm.data.planBeginTime > this.dataForm.data.planEndTime) {
                this.$message.error('计划工作时间（开始）不能大于计划工作时间（结束）');
                return;
            }
            this.setSaveParam(type);
            //签名校验
            if (!this.getDisable(this.NODE_CODE_VALUE.S1)) {
                if ((this.dataForm.data.isSign !== '0' && this.dataForm.data.isSign !== '1') || (!this.dataForm.data.hqReceiver && !this.dataForm.data.qfReceiver)) {
                    this.$message.error('请选择会签/签发人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S2)) {
                if (!this.dataForm.data.qfReceiver) {
                    this.$message.error('请选择签发人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S3)) {
                if (!this.dataForm.data.jsReceiver) {
                    this.$message.error('请选择接收人');
                    return;
                }
            }
            this.$client.workflowInstanceProcess(this.dataForm).then(res => {
                if (res.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.closeForm(1);
                }
            });
        },
        setSaveParam(type) {
            //处理方式
            this.dataForm.operationType = type;
            //是否是保存未提交
            this.dataForm.data.saveNotSubmit = type === 'save';
            //发起人
            this.dataForm.data.startUser = this.dataForm.user;

            if(!this.dataForm.data.saveNotSubmit){ //保存，下一步执行人还是自己
                 // 指定下一步只能是发起人处理
                if (this.dataForm.nodeCode === this.NODE_CODE_VALUE.S5 || this.dataForm.nodeCode === this.NODE_CODE_VALUE.S5) {
                    this.dataForm.nextUsers = [this.dataForm.data.startUser];
                }

                // 如果存在接收人，则指定下一步处理人
                if ((this.dataForm.curNodeCode === '' || this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S1) && this.dataForm.data.hqReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.hqReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S2 && this.dataForm.data.qfReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.qfReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3 && this.dataForm.data.jsReceiver) {
                    this.dataForm.nextUsers = [this.dataForm.data.jsReceiver];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4) {
                    //接收后发给变电站人员进入执行中
                    this.dataForm.nextRoles = [15];
                }
            }else{
                this.dataForm.nextUsers = [this.$store.state.userInfo.username]
            }

            //发起人才能终结
            if (this.dataForm.rejectNodes && this.dataForm.rejectNodes.length) {
                for (let i = this.dataForm.rejectNodes.length - 1; i >= 0; i--) {
                    if (this.dataForm.rejectNodes[i].code === 'elec_line_work_ticket_2_step_5') {
                        this.nextUsers.push(this.dataForm.rejectNodes[i].handler);
                        break;
                    }
                }
            }
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        //添加接地线/间断/变更
        addTableData(type) {
            if (type === 1) {
                //接地线
                if (!this.dataForm.data.groundWireList) {
                    this.dataForm.data.groundWireList = [];
                }
                let s = {
                    workTicketType: this.dataForm.data.type,
                    name: '',
                    code: ''
                };
                this.dataForm.data.groundWireList.push(s);
            } else if (type === 2) {
                //间断
                if (!this.dataForm.data.breakList) {
                    this.dataForm.data.breakList = [];
                }
                let s = {
                    workTicketType: this.dataForm.data.type,
                    breakTime: '', //工作间断时间
                    breakPermit: '', //间断工作许可人
                    breakCharge: '', //间断工作负责人
                    breakType: '', //间断方式
                    workTime: '', //工作开始时间
                    workPermit: '', //开始工作许可人
                    workCharge: '', //开始工作负责人
                    workType: '' //开始方式
                };
                this.dataForm.data.breakList.push(s);
            } else if (type === 3) {
                //变更
                if (!this.dataForm.data.changeList[0].itemList) {
                    this.dataForm.data.changeList[0].itemList = [];
                }
                let s = {
                    content: '', //变更内筒
                    sign: '', //许可人/签发人
                    charge: '', //工作负责人
                    changeTime: '' //变更时间
                };
                this.dataForm.data.changeList[0].itemList.push(s);
            }
        },
        //删除接电线
        deleteGroundWire({$index, row}) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning'
            }).then(() => {
                this.dataForm.data.groundWireList.splice($index, 1);
            });
        },
        //删除间断
        deleteBreak({$index, row}) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning'
            }).then(() => {
                this.dataForm.data.breakList.splice($index, 1);
            });
        },
        //删除变更
        deleteChangeItem({$index, row}) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning'
            }).then(() => {
                this.dataForm.data.changeList[0].itemList.splice($index, 1);
            });
        },
        //电子签章
        openSignDlg(type) {
            this.showSign = true;
            this.signIndex = type;
            this.$nextTick(() => {
                this.$refs.ref_elec_sign.init(type);
            });
        },
        signCallback(data, type) {
            switch (type) {
                case this.SIGN_TYPE.SIGN_ISSUER_SIGN:
                    //签发——工作票签发人签名
                    this.$set(this.sign.signIssuerSign, 'file', data);
                    this.sign.signIssuerSign.signatureName = '签发——工作票签发人签名';
                    this.dataForm.data.signIssuerSign = this.sign.signIssuerSign.file.id;
                    this.dataForm.data.signIssuerSignTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
                    break;
                case this.SIGN_TYPE.SIGN_COUNTERSIGN_SIGN:
                    //签发——工作票会签人签名
                    this.$set(this.sign.signCountersignSign, 'file', data);
                    this.sign.signCountersignSign.signatureName = '签发——工作票会签人签名';
                    this.dataForm.data.signCountersignSign = this.sign.signCountersignSign.file.id;
                    this.dataForm.data.signCountersignSignTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
                    break;
                case this.SIGN_TYPE.GET_ON_DUTY_CHARGE_SIGN:
                    //接收——值班负责人签名
                    this.$set(this.sign.getOnDutyChargeSign, 'file', data);
                    this.sign.getOnDutyChargeSign.signatureName = '接收——值班负责人签名';
                    this.dataForm.data.getOnDutyChargeSign = this.sign.getOnDutyChargeSign.file.id;
                    this.dataForm.data.getOnDutyChargeSignTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
                    break;
                case this.SIGN_TYPE.PERMIT_WORK_PERMIT_SIGN:
                    //工作许可——工作许可人签名
                    this.$set(this.sign.permitWorkPermitSign, 'file', data);
                    this.sign.permitWorkPermitSign.signatureName = '工作许可——工作许可人签名';
                    this.dataForm.data.permitWorkPermitSign = this.sign.permitWorkPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.PERMIT_DISPATCH_SIGN:
                    //工作许可——调度值班员（运维人员）签名
                    this.$set(this.sign.permitDispatchSign, 'file', data);
                    this.sign.permitDispatchSign.signatureName = '工作许可——工作许可人签名';
                    this.dataForm.data.permitDispatchSign = this.sign.permitDispatchSign.file.id;
                    break;
                case this.SIGN_TYPE.PERMIT_WORK_CHARGE_SIGN:
                    // 工作许可——工作负责人签名
                    this.$set(this.sign.permitWorkChargeSign, 'file', data);
                    this.sign.permitWorkChargeSign.signatureName = '工作许可——工作负责人签名';
                    this.dataForm.data.permitWorkChargeSign = this.sign.permitWorkChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.GUARDER_SIGN:
                    //监护人签名
                    this.$set(this.sign.guarderSign, 'file', data);
                    this.sign.guarderSign.signatureName = '监护人签名';
                    this.dataForm.data.guarderSign = this.sign.guarderSign.file.id;
                    break;
                case this.SIGN_TYPE.SAFE_CHARGE_SIGN:
                    //安全交代——工作班人员分组负责人签名
                    this.$set(this.sign.safeChargeSign, 'file', data);
                    this.sign.safeChargeSign.signatureName = '安全交代——工作班人员分组负责人签名';
                    this.dataForm.data.safeChargeSign = this.sign.safeChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_WORK_CHARGE_SIGN:
                    //工作票的终结——作业终结——工作负责人签名
                    this.$set(this.sign.finishWorkChargeSign, 'file', data);
                    this.sign.finishWorkChargeSign.signatureName = '工作票的终结——作业终结——工作负责人签名';
                    this.dataForm.data.finishWorkChargeSign = this.sign.finishWorkChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_WORK_PERMIT_SIGN:
                    //工作票的终结——作业终结——工作许可人签名
                    this.$set(this.sign.finishWorkPermitSign, 'file', data);
                    this.sign.finishWorkPermitSign.signatureName = '工作票的终结——作业终结——工作许可人签名';
                    this.dataForm.data.finishWorkPermitSign = this.sign.finishWorkPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_PERMIT_SIGN:
                    //工作票的终结——许可人措施终结——许可人签名
                    this.$set(this.sign.finishPermitSign, 'file', data);
                    this.sign.finishPermitSign.signatureName = '工作票的终结——许可人措施终结——许可人签名';
                    this.dataForm.data.finishPermitSign = this.sign.finishPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_DISPATCH_CHARGE_SIGN:
                    //工作票的终结——汇报调度——值班负责人签名
                    this.$set(this.sign.finishDispatchChargeSign, 'file', data);
                    this.sign.finishDispatchChargeSign.signatureName = '工作票的终结——汇报调度——值班负责人签名';
                    this.dataForm.data.finishDispatchChargeSign = this.sign.finishDispatchChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.FINISH_DISPATCH_SIGN:
                    //工作票的终结——汇报调度——值班调度员签名
                    this.$set(this.sign.finishDispatchSign, 'file', data);
                    this.sign.finishDispatchSign.signatureName = '工作票的终结——汇报调度——值班调度员签名';
                    this.dataForm.data.finishDispatchSign = this.sign.finishDispatchSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_TASK_CHARGE_SIGN:
                    //变更——工作任务——工作负责人签名
                    this.$set(this.sign.taskChargeSign, 'file', data);
                    this.sign.taskChargeSign.signatureName = '变更——工作任务——工作负责人签名';
                    this.dataForm.data.changeList[0].taskChargeSign = this.sign.taskChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_TASK_PERMIT_SIGN:
                    //变更——工作任务——工作负责人签名
                    this.$set(this.sign.taskPermitSign, 'file', data);
                    this.sign.taskPermitSign.signatureName = '变更——工作任务——工作负责人签名';
                    this.dataForm.data.changeList[0].taskPermitSign = this.sign.taskPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_ISSUER_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargeIssuerSign, 'file', data);
                    this.sign.chargeIssuerSign.signatureName = '变更——工作负责人——工作负责人签名';
                    this.dataForm.data.changeList[0].chargeIssuerSign = this.sign.chargeIssuerSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_PRE_CHARGE_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargePreChargeSign, 'file', data);
                    this.sign.chargePreChargeSign.signatureName = '变更——工作负责人——工作负责人签名';
                    this.dataForm.data.changeList[0].chargePreChargeSign = this.sign.chargePreChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_CURRENT_CHARGE_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargeCurrentChargeSign, 'file', data);
                    this.sign.chargeCurrentChargeSign.signatureName = '变更——工作负责人——现工作负责人签名';
                    this.dataForm.data.changeList[0].chargeCurrentChargeSign = this.sign.chargeCurrentChargeSign.file.id;
                    break;
                case this.SIGN_TYPE.CHANGE_CHARGE_PERMIT_SIGN:
                    //变更——工作负责人——工作负责人签名
                    this.$set(this.sign.chargePermitSign, 'file', data);
                    this.sign.chargePermitSign.signatureName = '变更——工作负责人——工作许可人签名';
                    this.dataForm.data.changeList[0].chargePermitSign = this.sign.chargePermitSign.file.id;
                    break;
                case this.SIGN_TYPE.DELAY_PERMIT_SIGN:
                    //延期——工作许可人签名
                    this.$set(this.sign.delayPermitSign, 'file', data);
                    this.sign.delayPermitSign.signatureName = '延期——工作许可人签名';
                    this.dataForm.data.delayList[0].delayPermitSign = this.sign.delayPermitSign.file.id;
                    break;
                case this.SIGN_TYPE.DELAY_CHARGE_SIGN:
                    //延期——工作负责人签名
                    this.$set(this.sign.delayChargeSign, 'file', data);
                    this.sign.delayChargeSign.signatureName = '延期——工作负责人签名';
                    this.dataForm.data.delayList[0].delayChargeSign = this.sign.delayChargeSign.file.id;
                    break;
                default:
            }
            // this.clearSignDom('.sign_so');
        },
        chooseRadio(val) {
            this.isShowWorkOrderCount = val === '1';
            if (!this.isShowWorkOrderCount) {
                this.dataForm.data.workOrderCount = '';
            }
        },
        initNodeCodeValue() {
            //第一种
            if (this.dataForm.data.type === 'elec_line_work_ticket_1') {
                this.NODE_CODE_VALUE.S1 = 'elec_line_work_ticket_1_step_1';
                this.NODE_CODE_VALUE.S2 = 'elec_line_work_ticket_1_step_2';
                this.NODE_CODE_VALUE.S3 = 'elec_line_work_ticket_1_step_3';
                this.NODE_CODE_VALUE.S4 = 'elec_line_work_ticket_1_step_4';
                this.NODE_CODE_VALUE.S5 = 'elec_line_work_ticket_1_step_5';
            } else if (this.dataForm.data.type === 'elec_line_work_ticket_2') {
                //第二种
                this.NODE_CODE_VALUE.S1 = 'elec_line_work_ticket_2_step_1';
                this.NODE_CODE_VALUE.S2 = 'elec_line_work_ticket_2_step_2';
                this.NODE_CODE_VALUE.S3 = 'elec_line_work_ticket_2_step_3';
                this.NODE_CODE_VALUE.S4 = 'elec_line_work_ticket_2_step_4';
                this.NODE_CODE_VALUE.S5 = 'elec_line_work_ticket_2_step_5';
            }
        },
        initNodeCodeMap() {
            this.nodeCodeMap = new Map();
            //第一种
            if (this.dataForm.data.type === 'elec_line_work_ticket_1') {
                this.nodeCodeMap.set('elec_line_work_ticket_1_step_1', 1);
                this.nodeCodeMap.set('elec_line_work_ticket_1_step_2', 2);
                this.nodeCodeMap.set('elec_line_work_ticket_1_step_3', 3);
                this.nodeCodeMap.set('elec_line_work_ticket_1_step_4', 4);
                this.nodeCodeMap.set('elec_line_work_ticket_1_step_5', 5);
                // this.nodeCodeMap.set('elec_line_work_ticket_1_step_6', 6);
            } else if (this.dataForm.data.type === 'elec_line_work_ticket_2') {
                //第二种
                this.nodeCodeMap.set('elec_line_work_ticket_2_step_1', 1);
                this.nodeCodeMap.set('elec_line_work_ticket_2_step_2', 2);
                this.nodeCodeMap.set('elec_line_work_ticket_2_step_3', 3);
                this.nodeCodeMap.set('elec_line_work_ticket_2_step_4', 4);
                this.nodeCodeMap.set('elec_line_work_ticket_2_step_5', 5);
                //this.nodeCodeMap.set('elec_line_work_ticket_2_step_6', 6);
            }
        },
        // 驳回步骤提交
        rejectSubmit() {
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error'
                });
                return;
            }
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: this.dataForm.definitionCode,
                        instanceId: this.dataForm.instanceId,
                        nodeCode: this.dataForm.nodeCode,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data: this.dataForm.data,
                        user: this.dataForm.user,
                        roles: this.dataForm.roles,
                        nextUsers: [this.currentRow.handler]
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closeForm(2);
                    });
            });
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        dataExport() {
            let data = {
                type: this.dataForm.definitionCode,
                ...this.dataForm.data
            };
            this.changeExportDataTime(data, 'planBeginTime');
            this.changeExportDataTime(data, 'planEndTime');
            this.changeExportDataTime(data, 'signIssuerSignTime');
            this.changeExportDataTime(data, 'signCountersignSignTime');
            this.changeExportDataTime(data, 'getSignTime');
            this.changeExportDataTime(data, 'permitSignTime');
            this.changeExportDataTime(data, 'safeWorkTeamSignaTime');
            this.changeExportDataTime(data, 'chargeSignTime');
            this.changeExportDataTime(data, 'delayDelayTerm');
            this.changeExportDataTime(data, 'delaySignTime');
            this.changeExportDataTime(data, 'finishWorkSignTime');
            this.changeExportDataTime(data, 'finishPermitSignTime');
            this.changeExportDataTime(data, 'hqQfReceiverTime');
            this.changeExportDataTime(data, 'hqReceiverTime');
            this.changeExportDataTime(data, 'qfReceiverTime');
            if (data.type === 'elec_line_work_ticket_1') {
                for (let i = 0; i < data.groundWireList.length; i++) {
                    let item = data.groundWireList[i];
                    data[`gname${i + 1}`] = item.name;
                    data[`gcode${i + 1}`] = item.code;
                }
            }


            fileDownload('/business/workTicketLines/download', {method: 'POST', data});
        },
        changeExportDataTime(data, name) {
            if (data[name] !== undefined && data[name] !== null && data[name] !== '') {
                data[name] = this.$dayjs(data[name]).format('YYYY年MM月DD日HH时mm分');
            }
        },
        //设置会签、签发、接收时间
        setReceiver(type) {
            if (type === 'hqQfReceiver') {
                this.dataForm.data.hqQfReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            } else if (type === 'hqReceiver') {
                this.dataForm.data.hqReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            } else if (type === 'qfReceiver') {
                this.dataForm.data.qfReceiverTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
            }
        },
        radioChange(data){
            // debugger
            this.isHqinput = false;
            this.isQfinput = false;
            if(data==="0"){
                this.isHqinput = true;
            }else{
                this.isQfinput = true;
            }
        }
    },
    mounted() {
        // 发计部人员 昆字运维
        let orgCodes = ['DZFJ', 'WB01'];
        this.$client.queryUserByOrgCode(orgCodes).then(data => {
            this.userList = data.data;
        });
        // 昆自运维
        let orgCodes1 = ['WB01'];
        this.$client.queryUserByOrgCode(orgCodes1).then(data => {
            this.userList1 = data.data;
        });
    },
    computed: {
        //是否可见
        getVisible() {
            return function(type) {
                if(this.isShowAll)return true;
                let b = false;
                //1、新建时只展示第一部分数据
                //2、详情页只需要展示已完成内容
                //3、处理页需要展示下一环节填写内容
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) - 1 : this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        //填写工作票
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        //会签
                        b = v >= tv && this.dataForm.data.isSign === '0';
                        break;
                    case this.NODE_CODE_VALUE.S3:
                        //签发
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        //接收
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        //终结
                        b = v >= tv;
                        break;
                    default:
                }
                return b;
            };
        },
        //是否可编辑
        getDisable() {
            return function(type) {
                if (this.dataForm.isInfo) {
                    //详情时不可编辑
                    return true;
                }
                let b = false;
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    v = this.dataForm.isInfo ? this.nodeCodeMap.get(curNodeCode) - 1 : this.nodeCodeMap.get(curNodeCode);
                    // v = this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        //填写工作票
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        //会签
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S3:
                        //签发
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        //接收
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        //终结
                        b = v !== tv;
                        break;
                    default:
                }
                return b;
            };
        },
        //签名
        signImg() {
            return function(fId) {
                if (fId) {
                    return `${this.$client.serverUrl}files/${fId}?token=${this.$store.state.token}`;
                }
                return '';
            };
        }
    }
};
</script>

<style lang="less" scoped="">
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }

    .button-fixed {
        margin: 0 !important;
    }

    /deep/ .card-margin {
        margin-bottom: 20px;
    }

    ::v-deep .el-radio__inner::after {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #fff;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.15s ease-in;
    }

    /deep/ .num-rule input::-webkit-outer-spin-button,
    /deep/ .num-rule input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    /deep/ .num-rule input[type='number'] {
        -moz-appearance: textfield;
    }

    // /deep/ .el-textarea__inner {
    //     color: white;
    // }

    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner{
        border-color: #235eff;
        background: #235eff;
    }
    .factory_name{
        margin-bottom: 4px;
        width: 360px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #ffffff;
        background-color: transparent;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
    }
</style>
